const DepositWalletAddress = () => {
  return (
    <div id="deposit-wallet-address" className="hashSection">
      <h1>Get Deposit Wallet Address</h1>
      <p className="api-path">
        POST /api/v1/digital-asset/deposit/address/inquiry
      </p>
      <p>
        <strong>Permission</strong>: Read, Deposit
      </p>
      <p>
        Returns a deposit address and networks for depositing cryptocurrency.
      </p>
      <div className="divider" />

      <h3>Request Body</h3>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>Field</th>
              <th>Mandatory</th>
              <th>Data type</th>
              <th style={{ minWidth: 400 }}>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>product</td>
              <td>Mandatory</td>
              <td>String</td>
              <td>Shortened product name, e.g., BTC.</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Request Code */}
      <div className="code-display">
        <p> Example Request</p>
        <pre>
          <code>
            {`{
    "product": "BTC"
}
`}
          </code>
        </pre>
      </div>

      <h3>Response</h3>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>Field</th>
              <th>Data type</th>
              <th style={{ minWidth: 400 }}>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>code</td>
              <td>String</td>
              <td></td>
            </tr>
            <tr>
              <td>message</td>
              <td>String</td>
              <td></td>
            </tr>
            <tr>
              <td>data.product</td>
              <td>String</td>
              <td>Shortened product name, e.g., BTC.</td>
            </tr>
            <tr>
              <td>data.networkId</td>
              <td>Integer</td>
              <td>
                ID of the network that is used to initiate a deposit request
              </td>
            </tr>
            <tr>
              <td>data.network</td>
              <td>String</td>
              <td>Name of the network</td>
            </tr>
            <tr>
              <td>data.address</td>
              <td>String</td>
              <td>
                The specific destination address where a user intends to deposit
                their cryptocurrency to their account{" "}
              </td>
            </tr>
            <tr>
              <td>data.destinationTagNumber</td>
              <td>String</td>
              <td>
                The destination tag or memo is required when transferring
                cryptocurrencies like XRP (Ripple) and XLM (Stellar) to another
                wallet
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Response Code */}
      <div className="code-display">
        <p> Example Response</p>
        <pre>
          <code>
            {`{
    "product": "XRP",
    "networkId": 1,
    "network": "",
    "address": "",
    "destinationTagAddress": ""
  }
`}
          </code>
        </pre>
      </div>
    </div>
  );
};
export default DepositWalletAddress;
