const GettingStarted = () => {
  return (
    <div id="getting-started">
      <h1 style={{ borderTop: 0 }}>Getting Started</h1>
      <p>
        InnovestX APIs allow you to integrate our digital asset features into
        your business's applications and services. To access the APIs, you must
        have InnovestX account with digital asset portfolio
      </p>
    </div>
  );
};

export default GettingStarted;
