const Products = () => {
  return (
    <div id="products" className="hashSection">
      <h1>Get Products</h1>
      <p className="api-path">GET /api/v1/digital-asset/products</p>
      <p>
        <strong>Permission</strong>: Read
      </p>
      <p>Returns an array of products available on the exchange</p>
      <div className="divider" />

      <h3>Response</h3>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>Field</th>
              <th>Data type</th>
              <th style={{ minWidth: 400 }}>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>code</td>
              <td>String</td>
              <td></td>
            </tr>
            <tr>
              <td>message</td>
              <td>String</td>
              <td></td>
            </tr>
            <tr>
              <td>data[].product</td>
              <td>String</td>
              <td>Shortened product name, e.g., BTC.</td>
            </tr>
            <tr>
              <td>data[].productType</td>
              <td>String</td>
              <td>
                the product description. Example of data
                <br />
                <span>- NationalCurrency</span>
                <br />
                <span>- CryptoCurrency</span>
              </td>
            </tr>
            <tr>
              <td>data[].decimalPlaces</td>
              <td>Integer</td>
              <td>
                The number of decimal places in which the product can be
                initiated. The maximum is 8. For example, BTC is allowed for 8
                decimal places, while THB uses 2 decimal places.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      
      {/* Response Code */}
      <div className="code-display">
        <p> Example Response</p>
        <pre>
          <code>
            {`{
    "code": "0000",
    "message": "SUCCESS",
    "data": [
        {
            "product": "BTC",
            "productType": "CryptoCurrency",
            "decimalPlaces": 8
        }
    ]
}
`}
          </code>
        </pre>
      </div>
    </div>
  );
};

export default Products;
