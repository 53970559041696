const AccountTradeHistory = () => {
  return (
    <div id="account-trade-history" className="hashSection">
      <h1>Get Account Trade</h1>
      <p className="api-path">
        POST /api/v1/digital-asset/account/trade/inquiry
      </p>
      <p>
        <strong>Permission</strong>: Read, Trading
      </p>
      <p>
        Retrieve trade history of an account. By default, it retrieves the 200
        most recent transactions, starting from the latest trade execution
        (index 0). The index value increases incrementally as trade executions
        move further into the past.
      </p>
      <div className="divider" />

      <h3>Request Body</h3>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>Field</th>
              <th>Mandatory</th>
              <th>Data type</th>
              <th style={{ minWidth: 400 }}>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>orderId</td>
              <td>Optional</td>
              <td>Long Integer</td>
              <td>
                A unique identifier assigned to an order for tracking and
                referencing purposes
              </td>
            </tr>
            <tr>
              <td>tradeId</td>
              <td>Optional</td>
              <td>Long Integer</td>
              <td>
                A unique identifier assigned to a matched trade as a reference
                for that particular transaction
              </td>
            </tr>
            <tr>
              <td>startTimeStamp</td>
              <td>Optional</td>
              <td>String</td>
              <td>
                The specified starting time to inquire about trade history,
                formatted as YYYY-MM-DDTHH:mm:ss.sssZ.
              </td>
            </tr>
            <tr>
              <td>endTimeStamp</td>
              <td>Optional</td>
              <td>String</td>
              <td>
                The specified ending time to inquire about trade history,
                formatted as YYYY-MM-DDTHH:mm:ss.sssZ.
              </td>
            </tr>
            <tr>
              <td>depth</td>
              <td>Optional</td>
              <td>Integer</td>
              <td>
                The number of trade history entries, set at 200, default at 200
              </td>
            </tr>
            <tr>
              <td>index</td>
              <td>Optional</td>
              <td>Integer</td>
              <td>
                The starting point within the trade history, starting from 0,
                which indicates the most recent trade.
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Request Code */}
      <div className="code-display">
        <p> Example Request</p>
        <pre>
          <code>
            {`{
    "orderId": null,
    "tradeId": null,
    "startTimeStamp": "2023-04-05T10:52:06.868Z",
    "endTimeStamp":  "2023-04-28T10:52:06.868Z",
    "depth": 2,
    "index": null
}
`}
          </code>
        </pre>
      </div>

      <h3>Response</h3>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>Field</th>
              <th>Data type</th>
              <th style={{ minWidth: 400 }}>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>code</td>
              <td>String</td>
              <td></td>
            </tr>
            <tr>
              <td>message</td>
              <td>String</td>
              <td></td>
            </tr>
            <tr>
              <td>data[].symbol</td>
              <td>String</td>
              <td>Trading symbol, e.g., BTCTHB.</td>
            </tr>
            <tr>
              <td>data[].orderId</td>
              <td>Long Integer</td>
              <td>A unique identifier assigned to an order</td>
            </tr>
            <tr>
              <td>data[].tradeId</td>
              <td>Long Integer</td>
              <td>A unique identifier assigned to a matched trade</td>
            </tr>
            <tr>
              <td>data[].clientOrderId</td>
              <td>Long Integer</td>
              <td>
                A user-assigned ID for the order, similar to a purchase order
                number used by companies, which helps in identifying and
                tracking future updates or changes
              </td>
            </tr>

            <tr>
              <td>data[].orderType</td>
              <td>String</td>
              <td>
                An order type identifier represented by a number.
                <br />
                <span>
                  <b>1</b> Market
                </span>
                <br />
                <span>
                  <b>2</b> Limit
                </span>
              </td>
            </tr>
            <tr>
              <td>data[].side</td>
              <td>String</td>
              <td>
                A numerical representation indicating one of the potential sides
                of a trade
                <br />
                <span>
                  <b>0</b> Buy
                </span>
                <br />
                <span>
                  <b>1</b> Sell
                </span>
              </td>
            </tr>
            <tr>
              <td>data[].quantity</td>
              <td>String</td>
              <td>The quantity of the asset being ordered.</td>
            </tr>
            <tr>
              <td>data[].remainingQuantity</td>
              <td>String</td>
              <td>
                The number of units remaining to be traded after the current
                order execution
              </td>
            </tr>
            <tr>
              <td>data[].price</td>
              <td>String</td>
              <td>The unit price at which a trade is executed</td>
            </tr>
            <tr>
              <td>data[].value</td>
              <td>String</td>
              <td>
                The total value of a transaction that is calculated as unit
                price * quantity executed.
              </td>
            </tr>
            <tr>
              <td>data[].orderTradeRevision</td>
              <td>Integer</td>
              <td>The revision number of a trade</td>
            </tr>
            <tr>
              <td>data[].fee</td>
              <td>String</td>
              <td>Trading fee associated with the trade</td>
            </tr>
            <tr>
              <td>data[].orderOriginator</td>
              <td>Integer</td>
              <td>The ID of the user who initiated the trade.</td>
            </tr>
            <tr>
              <td>data[].tradeDateTime</td>
              <td>String</td>
              <td>
                The trade date and time of execution, formatted as
                YYYY-MM-DDTHH:mm:ss.sssZ.
              </td>
            </tr>
            <tr>
              <td>data[].makerTaker</td>
              <td>String</td>
              <td>
                One of the following potential liquidity provider of a trade:
                <br />
                <span>Maker</span>
                <br />
                <span>Taker</span>
              </td>
            </tr>
            <tr>
              <td>data[].feeProduct</td>
              <td>String</td>
              <td>The product in which the fee was levied.</td>
            </tr>
            <tr>
              <td>data[].insideBid</td>
              <td>String</td>
              <td>
                The best (highest) price of the buy side of the order book at
                the time of the trade.
              </td>
            </tr>
            <tr>
              <td>data[].insideBidSize</td>
              <td>String</td>
              <td>
                The quantity of the best (highest) price of the buy side of the
                order book at the time of the trade.
              </td>
            </tr>
            <tr>
              <td>data[].insideAsk</td>
              <td>String</td>
              <td>
                The best (lowest) price of the sell side of the order book at
                the time of the trade.
              </td>
            </tr>
            <tr>
              <td>data[].insideAskSize</td>
              <td>String</td>
              <td>
                The quantity of the best (lowest) price of the sell side of the
                order book at the time of the trade.
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Response Code */}
      <div className="code-display">
        <p> Example Response</p>
        <pre>
          <code>
            {`{
    "code": "0000",
    "message": "SUCCESS",
    "data": [
        {
            "symbol": "XRPTHB",
            "orderId": 1,
            "tradeId": 2,
            "clientOrderId": 1234,
            "orderType": "Market",
            "side": "Buy",
            "quantity": "0.50000000",
            "remainingQuantity": "0.0000000",
            "price": "80.00000000",
            "value": "40.00000000",
            "orderTradeRevision": 1,
            "fee": "0.00100000",
            "orderOriginator": 1,
            "tradeDateTime": "2023-04-28T10:52:06.868Z",
            "makerTaker": "Taker",
            "feeProduct": "XRP",
            "insideBid": "79.00000000",
            "insideBidSize": "2.00000000",
            "insideAsk": "80.00000000",
            "insideAskSize": "11.99000000"
        }
    ]
}
`}
          </code>
        </pre>
      </div>
    </div>
  );
};

export default AccountTradeHistory;
