import React, { useState } from "react";
import { Layout, Image } from "antd";
import { themeColor } from "../../themes/theme-color";
import MenuIcon from "../../assets/icons/svg/menu.icon";
import DrawerMenu from "./DrawerMenu";
import InnovestXHDLogo from "../../assets/logo/INVX_LOGO.png";

const { Header } = Layout;

export default function HeaderLayout({ isDarkMode }) {
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <Header
      className="header-fixed"
      style={{
        padding: 0,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        backgroundColor: isDarkMode ? themeColor.dark : themeColor.light,
        borderBottom: `1px solid ${themeColor.gray}`,
      }}
    >
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          className="formobile"
          style={{
            height: 64,
            borderBottom: `1px solid ${themeColor.gray}`,
            flex: 1,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Image
            onClick={scrollToTop}
            preview={false}
            src={InnovestXHDLogo}
            width={124}
            style={{ marginLeft: 12 }}
          />
        </div>
        <div
          className="formobile"
          onClick={showDrawer}
          style={{ marginLeft: 20, marginRight: 20, marginTop: 4 }}
        >
          <MenuIcon />
        </div>
      </div>

      <DrawerMenu onClose={onClose} open={open} />
    </Header>
  );
}
