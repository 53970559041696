import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ConfigProvider, theme } from "antd";
import MainLayout from "./components/layouts/MainLayout";
import { checkIsDarkMode } from "./themes/themes-mode";
import MainPage from "./pages/MainPage";
import AboutPage from "./pages/AboutPage";
import "./global.css";

const { defaultAlgorithm } = theme;

function App() {
  const renderMainLayout = (component) => {
    return <MainLayout childComponent={component} />;
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "GraphikRegularTH",
        },
        algorithm: defaultAlgorithm,
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={renderMainLayout(MainPage())} />
          <Route path="/about" element={renderMainLayout(AboutPage())} />
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
