import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import * as HashKey from "../../constants/hash-key";
import { getDocumentTitle } from "../../util/StringUtil";
import {
  ApiKeySetup,
  ConstructingRequest,
  StandardErrorCodes,
} from "./introduction";
import { RestAPIOverview } from "./general-info";
import { OrderBook, TickerActivity } from "./market-data";
import {
  CancelOrder,
  EstimatedOrderFee,
  OpenOrders,
  OrderHistory,
  SendOrder,
} from "./order-management";
import {
  AllDepositTicket,
  AllWithdrawalTickets,
  CancelWithdraw,
  CreateDeposit,
  CreateWithdraw,
  DepositTicket,
  DepositWalletAddress,
  WithdrawalFee,
  WithdrawalTicket,
} from "./wallet-mangement";
import { AccountBalance, AccountTradeHistory } from "./account-management";
import {
  ProductFeeTier,
  Products,
  SymbolFeeTier,
  Symbols,
} from "./product-information";
import CreateWithdrawCrypto from "./wallet-mangement/CreateWithdrawCrypto";
import WithdrawWalletAddress from "./wallet-mangement/WithdrawWalletAddress";
import GettingStarted from "./introduction/GettingStarted";
import ApiPermissions from "./general-info/ApiPermissions";

const Section = ({ hash, childComponent }) => {
  const history = useNavigate();

  useEffect(() => {
    const el = document.getElementById(hash);
    const observer = new window.IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          history(`${hash}`);
          document.title = getDocumentTitle(hash);
          return;
        }
      },
      {
        root: null,
        threshold: 0.2, // set offset 0.1 means trigger if atleast 10% of element in viewport
      }
    );
    observer.observe(el);
    return () => observer.disconnect();
  }, [hash, history]);

  return (
    <div style={{ paddingBottom: 30 }} id={hash}>
      {childComponent}
      <div style={{ clear: "both" }}></div>
    </div>
  );
};
const ContentSections = () => {
  return (
    <>
      {/*Introduction*/}
      <Section
        hash={HashKey.GETTING_STARTED}
        childComponent={<GettingStarted />}
      />
      <Section hash={HashKey.APIKEY_SETUP} childComponent={<ApiKeySetup />} />
      <Section
        hash={HashKey.CREATING_REQUEST}
        childComponent={<ConstructingRequest />}
      />
      <Section
        hash={HashKey.STANDARD_ERROR_CODES}
        childComponent={<StandardErrorCodes />}
      />
      {/*General Info*/}
      <Section
        hash={HashKey.REST_API_OVERVIEW}
        childComponent={<RestAPIOverview />}
      />
      <Section
        hash={HashKey.API_PERMISSIONS}
        childComponent={<ApiPermissions />}
      />
      {/* <Section
        hash={HashKey.API_RATE_LIMITS}
        childComponent={<ApiRateLimits />}
      /> */}
      {/*Market Data*/}
      <Section hash={HashKey.ORDER_BOOK} childComponent={<OrderBook />} />
      <Section
        hash={HashKey.TICKER_ACTIVITY}
        childComponent={<TickerActivity />}
      />
      {/*Order Management*/}
      <Section hash={HashKey.SEND_ORDER} childComponent={<SendOrder />} />
      <Section hash={HashKey.CANCEL_ORDER} childComponent={<CancelOrder />} />
      <Section hash={HashKey.OPEN_ORDER} childComponent={<OpenOrders />} />
      <Section hash={HashKey.ORDER_HISTORY} childComponent={<OrderHistory />} />
      <Section
        hash={HashKey.ESTIMATED_ORDER_FEE}
        childComponent={<EstimatedOrderFee />}
      />
      {/*Account Management*/}
      <Section
        hash={HashKey.ACCOUNT_TRADE_HISTORY}
        childComponent={<AccountTradeHistory />}
      />
      <Section
        hash={HashKey.ACCOUNT_BALANCE}
        childComponent={<AccountBalance />}
      />
      {/*Wallet Management*/}
      <Section
        hash={HashKey.CREATE_DEPOSIT_FIAT}
        childComponent={<CreateDeposit />}
      />
      <Section
        hash={HashKey.DEPOSIT_TICKET}
        childComponent={<DepositTicket />}
      />
      <Section
        hash={HashKey.ALL_DEPOSIT_TICKETS}
        childComponent={<AllDepositTicket />}
      />
      <Section
        hash={HashKey.DEPOSIT_WALLET_ADDRESS}
        childComponent={<DepositWalletAddress />}
      />
      <Section
        hash={HashKey.WITHDRAW_WALLET_ADDRESS}
        childComponent={<WithdrawWalletAddress />}
      />
      <Section
        hash={HashKey.CREATE_WITHDRAW_FIAT}
        childComponent={<CreateWithdraw />}
      />
      <Section
        hash={HashKey.CREATE_WITHDRAW_CRYPTO}
        childComponent={<CreateWithdrawCrypto />}
      />
      <Section
        hash={HashKey.WITHDRAWAL_FEE}
        childComponent={<WithdrawalFee />}
      />
      <Section
        hash={HashKey.CANCEL_WITHDRAW}
        childComponent={<CancelWithdraw />}
      />
      <Section
        hash={HashKey.WITHDRAWAL_TICKET}
        childComponent={<WithdrawalTicket />}
      />
      <Section
        hash={HashKey.ALL_WITHDRAWAL_TICKETS}
        childComponent={<AllWithdrawalTickets />}
      />
      {/*Product Information*/}
      <Section hash={HashKey.PRODUCTS} childComponent={<Products />} />
      <Section hash={HashKey.SYMBOLS} childComponent={<Symbols />} />
      <Section
        hash={HashKey.PRODUCT_FEE_TIER}
        childComponent={<ProductFeeTier />}
      />
      <Section
        hash={HashKey.SYMBOL_FEE_TIER}
        childComponent={<SymbolFeeTier />}
      />
    </>
  );
};
export default ContentSections;
