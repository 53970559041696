const SendOrder = () => {
  return (
    <div id="create-order" className="hashSection">
      <h1>Send Order</h1>
      <p className="api-path">POST /api/v1/digital-asset/order/send</p>
      <p>
        <strong>Permission</strong>: Trading
      </p>
      <p>Create an order for buying and selling assets</p>
      <div className="divider" />

      <h3>Request Body</h3>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>Field</th>
              <th>Mandatory</th>
              <th>Data type</th>
              <th style={{ minWidth: 400 }}>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>symbol</td>
              <td>Mandatory</td>
              <td>String</td>
              <td>Trading symbol, e.g., BTCTHB.</td>
            </tr>
            <tr>
              <td>timeInForce</td>
              <td>Mandatory</td>
              <td>Integer</td>
              <td>
                An integer that represents the period during which the new order
                is executable. One of:
                <br />
                <span>
                  <b>1</b> GTC (good 'til canceled, the default)
                </span>
              </td>
            </tr>
            <tr>
              <td>clientOrderID</td>
              <td>Optional</td>
              <td>Long Integer</td>
              <td>
                A user-assigned ID for the order, similar to a purchase order
                number used by companies, which helps in identifying and
                tracking future updates or changes related to this specific
                order.
              </td>
            </tr>
            <tr>
              <td>side</td>
              <td>Mandatory</td>
              <td>Integer</td>
              <td>
                A numerical representation indicating one of the potential sides
                of a trade
                <br />
                <span>
                  <b>0</b> Buy
                </span>
                <br />
                <span>
                  <b>1</b> Sell
                </span>
              </td>
            </tr>
            <tr>
              <td>quantity</td>
              <td>Conditional Mandatory</td>
              <td>Decimal</td>
              <td>*The quantity of the asset being ordered.</td>
            </tr>
            <tr>
              <td>orderType</td>
              <td>Mandatory</td>
              <td>Integer</td>
              <td>
                An order type identifier represented by a number.
                <br />
                <span>
                  <b>1</b> Market
                </span>
                <br />
                <span>
                  <b>2</b> Limit
                </span>
              </td>
            </tr>
            <tr>
              <td>limitPrice</td>
              <td>Mandatory</td>
              <td>Decimal</td>
              <td>
                If the order is a Limit order, the price specified is the
                execution price for the order.
              </td>
            </tr>
            <tr>
              <td>value</td>
              <td>Conditional Mandatory</td>
              <td>Decimal</td>
              <td>*The value of THB to be initiated in an order.</td>
            </tr>
          </tbody>
        </table>

        <p style={{ width: "100%" }}>
          <b>*Note</b> - Both quantity and value should be as optional but mandatory to provide one of them.
        </p>
      </div>
      {/* Request Code */}
      <div className="code-display">
        <p> Example Request</p>
        <pre>
          <code>
            {`{
    "symbol": "XRPTHB",
    "timeInForce": 1,
    "side": 0,
    "quantity": 0.1,
    "orderType": 2,
    "limitPrice": 70,
    "clientOrderId": 10500
}
`}
          </code>
        </pre>
      </div>

      <h3>Response</h3>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>Field</th>
              <th>Data type</th>
              <th style={{ minWidth: 400 }}>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>code</td>
              <td>String</td>
              <td></td>
            </tr>
            <tr>
              <td>message</td>
              <td>String</td>
              <td></td>
            </tr>
            <tr>
              <td>data.orderId</td>
              <td>Long Integer</td>
              <td>
                A unique identifier assigned to an order for tracking and
                referencing purposes
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Response Code */}
      <div className="code-display">
        <p> Example Response</p>
        <pre>
          <code>
            {`{
    "code": "0000",
    "message": "SUCCESS",
    "data": {
        "orderId": 1
    }
}
`}
          </code>
        </pre>
      </div>
    </div>
  );
};

export default SendOrder;
