

const AboutPage = ()=>{
    return(
        <>
            <div className="page-wrapper">
                <div className="content">


                </div>
            </div>
        </>
    )
}

export default AboutPage;