/* eslint-disable jsx-a11y/anchor-is-valid */
const RestAPIOverview = () => {
  return (
    <div id="rest-api-overview" className="hashSection">
      <h1>REST API Overview</h1>
      <p>
        InnovestX RESTful API enables you to effectively handle digital asset's
        orders, account balance tracking, wallet management, and grants access
        to order book information, ticker activity updates, trade execution, and
        fund transfers.
      </p>
      <p>
        To execute any API operation,{" "}
        <a href="#authenticate-message">API key authentication</a> is mandatory,
        and each API is limited to performing only the specified permissions
        assigned to it.
      </p>
      <p>
        <b>API Endpoint URL : </b>
        {`/api/v1/digital-asset/{path}`}
      </p>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>API</th>
              <th>Method</th>
              <th>Path</th>
              <th>Required Permissions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Subscribe Level 2</td>
              <td>POST</td>
              <td>/orderbook/lvl2</td>
              <td>Trading, Read</td>
            </tr>
            <tr>
              <td>Subscribe Ticker</td>
              <td>POST </td>
              <td>/ticker/subscribe </td>
              <td>Trading, Read </td>
            </tr>
            <tr>
              <td>Send Order</td>
              <td>POST</td>
              <td>/order/send </td>
              <td>Trading</td>
            </tr>
            <tr>
              <td>Cancel Order</td>
              <td>POST</td>
              <td>/order/cancel</td>
              <td>Trading</td>
            </tr>
            <tr>
              <td>Get Open Orders</td>
              <td>GET </td>
              <td>/order/open/inquiry </td>
              <td>Trading, Read </td>
            </tr>
            <tr>
              <td>Get Order History</td>
              <td>POST </td>
              <td>/order/history/inquiry</td>
              <td>Trading, Read </td>
            </tr>
            <tr>
              <td>Get Estimate Fee</td>
              <td>POST</td>
              <td>/order/fee/inquiry</td>
              <td>Trading, Read </td>
            </tr>
            <tr>
              <td>Get Account Trade</td>
              <td>POST </td>
              <td>/account/trade/inquiry </td>
              <td>Trading, Read </td>
            </tr>
            <tr>
              <td>Get Account Balance</td>
              <td>GET </td>
              <td>/account/balance/inquiry </td>
              <td>Trading, Withdraw, Deposit, Read </td>
            </tr>
            <tr>
              <td>Deposits Fiat</td>
              <td>POST</td>
              <td>/deposit/fiat/create</td>
              <td>Deposit</td>
            </tr>
            <tr>
              <td>Get Deposit Ticket</td>
              <td>POST </td>
              <td>/deposit/inquiry</td>
              <td>Deposit, Read </td>
            </tr>
            <tr>
              <td>Get All Deposit Tickets</td>
              <td>POST</td>
              <td>/deposit/all/inquiry</td>
              <td>Deposit, Read </td>
            </tr>
            <tr>
              <td>Get Deposit Wallet Address</td>
              <td>POST </td>
              <td>/deposit/address/inquiry</td>
              <td>Deposit, Read </td>
            </tr>
            <tr>
              <td>Get Withdraw Wallet Address</td>
              <td>POST</td>
              <td>/withdraw/address/inquiry</td>
              <td>Withdraw, Read </td>
            </tr>
            <tr>
              <td>Withdraws Fiat</td>
              <td>POST </td>
              <td>/withdraw/fiat/create</td>
              <td>Withdraw</td>
            </tr>
            <tr>
              <td>Withdraws Crypto</td>
              <td>POST </td>
              <td>/withdraw/crypto/create</td>
              <td>Withdraw</td>
            </tr>
            <tr>
              <td>Get Withdrawal Fee</td>
              <td>POST </td>
              <td>/withdraw/fee</td>
              <td>Withdraw, Read </td>
            </tr>
            <tr>
              <td>Cancel Withdraw</td>
              <td>POST</td>
              <td>/withdraw/cancel</td>
              <td>Withdraw</td>
            </tr>
            <tr>
              <td>Get Withdrawal Ticket</td>
              <td>POST</td>
              <td>/withdraw/inquiry</td>
              <td>Withdraw, Read </td>
            </tr>
            <tr>
              <td>Get All Withdrawal Tickets</td>
              <td>POST </td>
              <td>/withdraw/all/inquiry</td>
              <td>Withdraw, Read </td>
            </tr>
            <tr>
              <td>Get Products</td>
              <td>GET </td>
              <td>/products</td>
              <td>Read </td>
            </tr>
            <tr>
              <td>Get Symbols</td>
              <td>GET </td>
              <td>/symbols</td>
              <td>Read</td>
            </tr>
            <tr>
              <td>Get Product Fee Tier</td>
              <td>POST </td>
              <td>/product/fee/tier</td>
              <td>Read </td>
            </tr>
            <tr>
              <td>Get Symbol Fee Tier</td>
              <td>POST</td>
              <td>/symbol/fee/tier</td>
              <td>Read </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RestAPIOverview;
