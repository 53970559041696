const OpenOrders = () => {
  return (
    <div id="open-orders" className="hashSection">
      <h1>Get Open Orders</h1>
      <p className="api-path">GET /api/v1/digital-asset/order/open/inquiry</p>
      <p>
        <strong>Permission</strong>: Read, Trading
      </p>
      <p>Retrieves open orders for an account</p>
      <div className="divider" />

      <h3>Response</h3>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>Field</th>
              <th>Data type</th>
              <th style={{ minWidth: 400 }}>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>code</td>
              <td>String</td>
              <td></td>
            </tr>
            <tr>
              <td>message</td>
              <td>String</td>
              <td></td>
            </tr>
            <tr>
              <td>data[].side</td>
              <td>Integer</td>
              <td>
                A numerical representation indicating one of the potential sides
                of a trade
                <br />
                <span>
                  <b>0</b> Buy
                </span>
                <br />
                <span>
                  <b>1</b> Sell
                </span>
              </td>
            </tr>
            <tr>
              <td>data[].orderId</td>
              <td>Long Integer</td>
              <td>A unique identifier assigned to an order</td>
            </tr>
            <tr>
              <td>data[].price</td>
              <td>String</td>
              <td>The unit price at which a trade is executed</td>
            </tr>
            <tr>
              <td>data[].quantity</td>
              <td>String</td>
              <td>The quantity of the asset being ordered.</td>
            </tr>
            <tr>
              <td>data[].symbol</td>
              <td>String</td>
              <td>Trading symbol, e.g., BTCTHB.</td>
            </tr>
            <tr>
              <td>data[].orderType</td>
              <td>String</td>
              <td>
                An order type identifier represented by a number.
                <br />
                <span>
                  <b>1</b> Market
                </span>
                <br />
                <span>
                  <b>2</b> Limit
                </span>
              </td>
            </tr>
            <tr>
              <td>data[].clientOrderId</td>
              <td>Long Integer</td>
              <td>
                A user-assigned ID for the order, similar to a purchase order
                number used by companies, which helps in identifying and
                tracking future updates or changes.
              </td>
            </tr>
            <tr>
              <td>data[].orderState</td>
              <td>String</td>
              <td>
                The current state of the order. One of:
                <br />
                <span>
                  <b>0</b> Unknown
                </span>
                <br />
                <span>
                  <b>1</b> Working
                </span>
                <br />
                <span>
                  <b>2</b> Rejected
                </span>
                <br />
                <span>
                  <b>3</b> Canceled
                </span>
                <br />
                <span>
                  <b>4</b> Expired
                </span>
                <br />
                <span>
                  <b>5</b> Fully Executed
                </span>
              </td>
            </tr>
            <tr>
              <td>data[].receiveDateTime</td>
              <td>String</td>
              <td>Time stamp of the order</td>
            </tr>
            <tr>
              <td>data[].origQuantity</td>
              <td>String</td>
              <td>The original quantity of the order being created</td>
            </tr>
            <tr>
              <td>data[].quantityExecuted</td>
              <td>String</td>
              <td>
                The quantity that has been executed if the open order has been
                at least partially executed
              </td>
            </tr>
            <tr>
              <td>data[].avgPrice</td>
              <td>String</td>
              <td>
                The average executed price for the instrument in the order.
              </td>
            </tr>
            <tr>
              <td>data[].changeReason</td>
              <td>String</td>
              <td>
                If the order has been changed, this string value holds the
                reason. One of:
                <br />
                <span>
                  <b>0</b> Unknown
                </span>
                <br />
                <span>
                  <b>1</b> NewInputAccepted
                </span>
                <br />
                <span>
                  <b>2</b> NewInputRejected
                </span>
                <br />
                <span>
                  <b>3</b> OtherRejected
                </span>
                <br />
                <span>
                  <b>4</b> Expired
                </span>
                <br />
                <span>
                  <b>5</b> Trade
                </span>
                <br />
                <span>
                  <b>6</b> SystemCanceled_NoMoreMarket
                </span>
                <br />
                <span>
                  <b>7</b> SystemCanceled_BelowMinimum
                </span>
                <br />
                <span>
                  <b>8</b> SystemCanceled_PriceCollar
                </span>
                <br />
                <span>
                  <b>9</b> SystemCanceled_MarginFailed
                </span>
                <br />
                <span>
                  <b>100</b> UserModified
                </span>
              </td>
            </tr>
            <tr>
              <td>data[].origOrderId</td>
              <td>Long Integer</td>
              <td>
                If the order has been changed, this is the ID of the original
                order.
              </td>
            </tr>
            <tr>
              <td>data[].origCiOrdId</td>
              <td>Long Integer</td>
              <td>
                If the order has been changed, this is the ID of the original
                client order ID.
              </td>
            </tr>
            <tr>
              <td>data[].insideAsk</td>
              <td>String</td>
              <td>
                If this order is a quote, this value is the Inside Ask price.
              </td>
            </tr>
            <tr>
              <td>data[].insideAskSize</td>
              <td>String</td>
              <td>
                If this order is a quote, this value is the quantity of the
                Inside Ask quote.
              </td>
            </tr>
            <tr>
              <td>data[].insideBid</td>
              <td>String</td>
              <td>
                If this order is a quote, this value is the Inside Bid price.
              </td>
            </tr>
            <tr>
              <td>data[].insideBidSize</td>
              <td>String</td>
              <td>
                If this order is a quote, this value is the quantity of the
                Inside Bid quote.
              </td>
            </tr>
            <tr>
              <td>data[].lastTradePrice </td>
              <td>String</td>
              <td>The last price that this instrument traded at.</td>
            </tr>
            <tr>
              <td>data[].rejectReason </td>
              <td>String</td>
              <td>
                If this open order has been rejected, this string holds the
                reason for the rejection.
              </td>
            </tr>
            <tr>
              <td>data[].cancelReason </td>
              <td>String</td>
              <td>
                If this order has been canceled, this string holds the
                cancelation reason.
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Response Code */}
      <div className="code-display">
        <p> Example Response</p>
        <pre>
          <code>
            {`{
    "code": "0000",
    "message": "SUCCESS",
    "data": [
        {
            "side": "Buy",
            "orderId": 1,
            "price": "1000.0000000",
            "quantity": "0.01000000",
            "symbol": "BTCTHB",
            "orderType": "Limit",
            "clientOrderId": 0,
            "orderState": "Working",
            "receiveDateTime": "2023-05-03T00:00:00.646Z",
            "origQuantity": "0.01000000",
            "quantityExecuted": "10.0000000",
            "avgPrice": "10.0000000",
            "changeReason": "NewInputAccepted",
            "origOrderId": 1,
            "origCiOrdId": 0,
            "insideAsk": "123.0000000",
            "insideAskSize": "100.0000000",
            "insideBid": "100.00000000"",
            "insideBidSize": "3.00000000",
            "isQuote": false,
            "lastTradePrice": "123.00000000",
            "rejectReason": "",
            "cancelReason": ""
        }
    ]
}
`}
          </code>
        </pre>
      </div>
    </div>
  );
};

export default OpenOrders;
