import ContentSections from "../components/sections/ContentSections";

const MainPage = () => {
    return (
        <>
            <div className="page-wrapper">
                <div className="dark-box"></div>
                <div className="content">
                    <ContentSections/>
                </div>
                <div className="dark-box"></div>
            </div>
        </>
    );
};

export default MainPage;
