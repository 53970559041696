import React from "react";

export default function FooterLayout() {
    return (
        <div className="footer">
            <div className="row">
                <div className="group-column">
                    <div className='column mobile-center'>
                        © Copyright © 2023 InnovestX Securities Co., Ltd.<br/>All rights reserved
                    </div>
                </div>
                <div className="group-column">
                    <div className="column" >
                       <div>Email<br/>innovestx_contact@scb.co.th</div>
                    </div>
                    <div className="column">
                        InnovestX Call Center<br/>02-949-1999
                    </div>
                    <div className="column">
                        e-Business Department<br/>02-949-1234
                    </div>
                </div>
            </div>
        </div>
    );
}
