const WithdrawalFee = () => {
  return (
    <div id="withdrawal-fee" className="hashSection">
      <h1>Get Withdrawal Fee</h1>
      <p className="api-path">POST /api/v1/digital-asset/withdraw/fee</p>
      <p>
        <strong>Permission</strong>: Read, Withdraw
      </p>
      <p>Gets an estimate of a fee for a withdrawal.</p>
      <div className="divider" />

      <h3>Request Body</h3>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>Field</th>
              <th>Mandatory</th>
              <th>Data type</th>
              <th style={{ minWidth: 400 }}>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>product</td>
              <td>Mandatory</td>
              <td>String</td>
              <td>Shortened product name, e.g., BTC.</td>
            </tr>
            <tr>
              <td>amount</td>
              <td>Mandatory</td>
              <td>Decimal</td>
              <td>
                The name of the template being used. Templates vary from Account
                Provider to Account Provider, e.g., ToExternalBitcoinAddress
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Request Code */}
      <div className="code-display">
        <p> Example Request</p>
        <pre>
          <code>
            {`{
    "product": BTC,
    "amount": 1
}
`}
          </code>
        </pre>
      </div>

      <h3>Response</h3>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>Field</th>
              <th>Data type</th>
              <th style={{ minWidth: 400 }}>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>code</td>
              <td>String</td>
              <td></td>
            </tr>
            <tr>
              <td>message</td>
              <td>String</td>
              <td></td>
            </tr>
            <tr>
              <td>data.feeAmount</td>
              <td>String</td>
              <td>The amount of any fee that was charged</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Response Code */}
      <div className="code-display">
        <p> Example Response</p>
        <pre>
          <code>
            {`{
    "status": "0000",
    "message": "success",
    "data": {
        "feeAmount": "1.06000000"
    }
}`}
          </code>
        </pre>
      </div>
    </div>
  );
};
export default WithdrawalFee;
