const SymbolFeeTier = () => {
  return (
    <div id="symbol-fee-tier" className="hashSection">
      <h1>Get Symbol Fee Tier</h1>
      <p className="api-path">POST /api/v1/digital-asset/symbol/fee/tier</p>
      <p>
        <strong>Permission</strong>: Read
      </p>
      <p>Retrieves fee of a symbol.</p>
      <div className="divider" />

      <h3>Request Body</h3>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>Field</th>
              <th>Mandatory</th>
              <th>Data type</th>
              <th style={{ minWidth: 400 }}>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>symbol</td>
              <td>Mandatory</td>
              <td>String</td>
              <td>Trading symbol, e.g., BTCTHB.</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Request Code */}
      <div className="code-display">
        <p> Example Request</p>
        <pre>
          <code>
            {`{
    "symbol":"BTCTHB"
}
`}
          </code>
        </pre>
      </div>

      <h3>Response</h3>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>Field</th>
              <th>Data type</th>
              <th style={{ minWidth: 400 }}>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>code</td>
              <td>String</td>
              <td></td>
            </tr>
            <tr>
              <td>message</td>
              <td>String</td>
              <td></td>
            </tr>
            <tr>
              <td>data.symbol</td>
              <td>String</td>
              <td>Trading symbol, e.g., BTCTHB.</td>
            </tr>
            <tr>
              <td>data.feeAmount</td>
              <td>String</td>
              <td>The amount of any fee that was charged</td>
            </tr>
            <tr>
              <td>data.feeType</td>
              <td>String</td>
              <td>
                The calculation type for the fee. One of FlatRate or Percentage
              </td>
            </tr>
            <tr>
              <td>data.orderType</td>
              <td>String</td>
              <td>
                The type of order on which the fee is defined e.g., all order
                types, market, limit
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Response Code */}
      <div className="code-display">
        <p> Example Response</p>
        <pre>
          <code>
            {`{
    "code": "0000",
    "message": "SUCCESS",
    "data": 
        {
            "symbol": "BTCTHB",
            "feeAmount": "0.10000000",
            "feeType": "Percentage",
            "orderType": "Unknown"
        }
}
`}
          </code>
        </pre>
      </div>
    </div>
  );
};

export default SymbolFeeTier;
