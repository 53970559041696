import { Menu, Image } from "antd";
import React, { useEffect, useState } from "react";
import InnovestXHDLogo from "../../assets/logo/INVX_LOGO.png";
import { themeColor } from "../../themes/theme-color";
import * as HashKey from "../../constants/hash-key";
import { useLocation } from "react-router-dom";
import { getActiveKeySubMenu } from "../../util/MenuUtil";
import { getDocumentTitle } from "../../util/StringUtil";
import { menuItems } from "./MenuItems";

const onClick = (e) => {
  document.title = getDocumentTitle(e.key);
};

const SideBarLayout = () => {
  const OPEN_KEYS = ["sub1"];
  const [openKeys, setOpenKeys] = useState(OPEN_KEYS);
  const { hash } = useLocation();

  useEffect(() => {
    const openKey = getActiveKeySubMenu(hash);
    setOpenKeys(openKey);
  }, [hash]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const onOpenChange = (openKeys) => setOpenKeys([...openKeys]);
  return (
    <>
      <div className="toc-wrapper">
        <div
          style={{
            height: 64,
            borderBottom: `1px solid ${themeColor.gray}`,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Image
            onClick={scrollToTop}
            preview={false}
            src={InnovestXHDLogo}
            width={124}
            style={{ marginLeft: 12 }}
          />
        </div>
        <Menu
          theme={"light"}
          onClick={onClick}
          defaultSelectedKeys={[HashKey.APIKEY_SETUP]}
          selectedKeys={[hash]}
          mode="inline"
          items={menuItems}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          inlineIndent={10}
        />
      </div>
    </>
  );
};

export default SideBarLayout;
