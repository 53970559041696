const OrderBook = () => {
  return (
    <div id="subscribe-level-2" className="hashSection">
      <h1>Subscribe Level 2</h1>
      <p className="api-path">POST /api/v1/digital-asset/orderbook/lvl2</p>
      <p>
        <strong>Permission</strong>: Read, Trading
      </p>
      <p>
        Fetches latest Level 2 ticker data for a particular symbol and set up
        continuous updates for Level 2 market data events. With Level 2, the
        user can define the desired depth of market information for both the bid
        and ask sides.
      </p>
      <div className="divider" />

      <h3>Request Body</h3>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>Field</th>
              <th>Mandatory</th>
              <th>Data type</th>
              <th style={{ minWidth: 400 }}>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>symbol</td>
              <td>Mandatory</td>
              <td>String</td>
              <td>Trading symbol, e.g., BTCTHB.</td>
            </tr>
            <tr>
              <td>depth</td>
              <td>Optional</td>
              <td>Integer</td>
              <td>The number of records to be returned, default at 100</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Request Code */}
      <div className="code-display">
        <p> Example Request</p>
        <pre>
          <code>
            {`{
    "symbol": "BTCTHB",
    "depth": 100 
}
`}
          </code>
        </pre>
      </div>

      <h3>Response</h3>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>Field</th>
              <th>Data type</th>
              <th style={{ minWidth: 400 }}>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>code</td>
              <td>String</td>
              <td></td>
            </tr>
            <tr>
              <td>message</td>
              <td>String</td>
              <td></td>
            </tr>
            <tr>
              <td>data[].mdUpdateId</td>
              <td>Integer</td>
              <td>
                Market Data Update ID.refers to a sequential ID identifies the
                order in which the update was created.
              </td>
            </tr>
            <tr>
              <td>data[].actionDateTime</td>
              <td>String</td>
              <td>
                The specific date and time that the snapshot was captured or the
                event occurred
              </td>
            </tr>
            <tr>
              <td>data[].actionType</td>
              <td>Integer</td>
              <td>
                This value shows whether this data is:
                <br />
                <span>
                  <b>0</b> new
                </span>
                <br />
                <span>
                  <b>1</b> update
                </span>
                <br />
                <span>
                  <b>2</b> deletion
                </span>
              </td>
            </tr>
            <tr>
              <td>data[].lastTradePrice</td>
              <td>String</td>
              <td>The price at which the symbol was last traded.</td>
            </tr>
            <tr>
              <td>data[].noOfOrders</td>
              <td>Integer</td>
              <td>Total number of orders</td>
            </tr>
            <tr>
              <td>data[].price</td>
              <td>String</td>
              <td>Bid or Ask price for the quantity </td>
            </tr>
            <tr>
              <td>data[].quantity</td>
              <td>String</td>
              <td>Quantity available at a given Bid or Ask price </td>
            </tr>
            <tr>
              <td>data[].side</td>
              <td>Integer</td>
              <td>
                One of: <br />
                <span>
                  <b>0</b> Buy
                </span>
                <br />
                <span>
                  <b>1</b> Sell
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Response Code */}
      <div className="code-display">
        <p> Example Response</p>
        <pre>
          <code>
            {`{
    "code": "0000",
    "message": "SUCCESS",
    "data": [
        {
            "mdUpdateId": 15646698,
            "actionDateTime": "2023-04-19T03:44:14.696Z",
            "actionType": 0,
            "lastTradePrice": "1009796.09000000",
            "noOfOrders": 1,
            "price": "185734.94000000",
            "quantity": "0.00200000",
            "side": 0
        },
        {
            "mdUpdateId": 15646698,
            "actionDateTime": "2023-04-19T03:44:14.696Z",
            "actionType": 0,
            "lastTradePrice": "1009796.09000000",
            "noOfOrders": 1,
            "price": "183168.28000000",
            "quantity": "0.00200000",
            "side": 0
        }
    ]
}
`}
          </code>
        </pre>
      </div>
    </div>
  );
};

export default OrderBook;
