const AccountBalance = () => {
  return (
    <div id="account-balance" className="hashSection">
      <h1>Get Account Balance</h1>
      <p className="api-path">
        GET /api/v1/digital-asset/account/balance/inquiry
      </p>
      <p>
        <strong>Permission</strong>: Read, Trading, Deposit, Withdraw
      </p>
      <p>Returns account balance.</p>
      <div className="divider" />

      <h3>Response</h3>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>Field</th>
              <th>Data type</th>
              <th style={{ minWidth: 400 }}>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>code</td>
              <td>String</td>
              <td></td>
            </tr>
            <tr>
              <td>message</td>
              <td>String</td>
              <td></td>
            </tr>
            <tr>
              <td>data[].product</td>
              <td>String</td>
              <td>Shortened product name, e.g., BTC.</td>
            </tr>
            <tr>
              <td>data[].amount</td>
              <td>String</td>
              <td>Unit amount of the product, e.g., 100 or 116.25.</td>
            </tr>
            <tr>
              <td>data[].hold</td>
              <td>String</td>
              <td>
                The amount of currency held is not available for trade. Held
                amounts cannot be withdrawn while a trade is pending unless the
                order is canceled, after which the held amounts will be
                released.
              </td>
            </tr>
            <tr>
              <td>data[].pendingDeposits</td>
              <td>String</td>
              <td>
                Deposits have been received but are still pending clearance for
                trading.
              </td>
            </tr>
            <tr>
              <td>data[].pendingWithdraws</td>
              <td>String</td>
              <td>
                Withdraws have been received but are still pending clearance
                from the account.
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Response Code */}
      <div className="code-display">
        <p> Example Response</p>
        <pre>
          <code>
            {`{
    "code": "0000",
    "message": "SUCCESS",
    "data": [
        {
            "product": "BTC",
            "amount": "0.00000000",
            "hold": "0.00000000",
            "pendingDeposit": "0.00000000",
            "pendingWithdraw": "0.00000000"
        }
    ]
}
`}
          </code>
        </pre>
      </div>
    </div>
  );
};

export default AccountBalance;
