import { Menu, Image, Drawer } from "antd";
import React, { useEffect, useState } from "react";
import InnovestXHDLogo from "../../assets/logo/INVX_LOGO.png";
import * as HashKey from "../../constants/hash-key";
import { useLocation } from "react-router-dom";
import { getActiveKeySubMenu } from "../../util/MenuUtil";
import { getDocumentTitle } from "../../util/StringUtil";
import { menuItems } from "./MenuItems";

const DrawerMenu = ({ onClose, open }) => {
  const OPEN_KEYS = ["sub1"];
  const [openKeys, setOpenKeys] = useState(OPEN_KEYS);
  const { hash } = useLocation();

  useEffect(() => {
    const openKey = getActiveKeySubMenu(hash);
    setOpenKeys(openKey);
    if (hash === "#getting-started") {
      console.log("this start mobile");
      setTimeout(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }, 100);
    }
  }, [hash]);

  const onClick = (e) => {
    document.title = getDocumentTitle(e.key);
    onClose();
  };

  const onOpenChange = (openKeys) => setOpenKeys([...openKeys]);
  return (
    <>
      <Drawer
        title={
          <div>
            <Image preview={false} src={InnovestXHDLogo} width={124} />
          </div>
        }
        placement="right"
        onClose={onClose}
        open={open}
        headerStyle={{ paddingTop: 20, paddingBottom: 14 }}
      >
        <Menu
          theme={"light"}
          onClick={onClick}
          defaultSelectedKeys={[HashKey.APIKEY_SETUP]}
          selectedKeys={[hash]}
          mode="inline"
          items={menuItems}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          inlineIndent={10}
        />
      </Drawer>
    </>
  );
};

export default DrawerMenu;
