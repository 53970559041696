/* eslint-disable jsx-a11y/anchor-is-valid */
import { Image } from "antd";
import keySetUpImage from "../../../assets/images/keySetUpImage.png";
import userProfile from "../../../assets/images/userProfile.png";
import apiKey from "../../../assets/images/apiKey.png";

const ApiKeySetup = () => {
  return (
    <div id="apikey-setup" className="hashSection">
      <h1>API Key Setup</h1>
      <p>
        <h3>How to create a new API Key</h3>
        1. Sign in to{" "}
        <a href="https://trade.innovestxonline.com">
          trade.innovestxonline.com
        </a>
        <br />
        2. Go to Account <Image src={userProfile} width={18} preview={false} /> and select API
        Keys
        <Image src={apiKey} height={20} width={70} preview={false} /> <br />
        3. Select Generate API Key <br />
        4. Choose API key permission to allow the API key to access
        <br />
        5. Enter IP addresses for access restrictions (If any) <br />
        6. Review your API Key, and then proceed by clicking "Generate" <br />
        7. Complete Two-Factor Authentication process{" "}
        <a href="https://www.innovestx.co.th/products/product-user-guide/detail/manual/DA-manual-webtrade11">
          (see how to register Two-Factor Authentication)
        </a>{" "}
        <br />
        8. Store your API key and secret in secure location. The API secret will
        be displayed only once upon successfully generating the API key. <br />
      </p>
      <p>
        See full user manual{" "}
        <a href="https://www.innovestx.co.th/products/product-user-guide/detail/manual/DA-manual-webtrade10">
          here
        </a>
      </p>
      <p>
        <div style={{ width: "100%" }}>
          <Image src={keySetUpImage} />
        </div>
      </p>
    </div>
  );
};

export default ApiKeySetup;
