
const capitalizeWords = (str) => {
    return str
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};


const getDocumentTitle = (hash) => {
   let title =  hash.replace('#' , '').replace('-' , ' ')
    title = 'InnovestX Open API | ' + title
    return title
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

export  {
    capitalizeWords,
    getDocumentTitle
}