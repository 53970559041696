const ProductFeeTier = () => {
  return (
    <div id="product-fee-tier" className="hashSection">
      <h1>Get Product Fee Tier</h1>
      <p className="api-path">POST /api/v1/digital-asset/product/fee/tier</p>
      <p>
        <strong>Permission</strong>: Read
      </p>
      <p>Retrieves fee of a product</p>
      <div className="divider" />

      <h3>Request Body</h3>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>Field</th>
              <th>Mandatory</th>
              <th>Data type</th>
              <th style={{ minWidth: 400 }}>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>product</td>
              <td>Mandatory</td>
              <td>String</td>
              <td>Shortened product name, e.g., BTC.</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Request Code */}
      <div className="code-display">
        <p> Example Request</p>
        <pre>
          <code>
            {`{
    "product": "BTCTHB"
}
`}
          </code>
        </pre>
      </div>

      <h3>Response</h3>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>Field</th>
              <th>Data type</th>
              <th style={{ minWidth: 400 }}>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>code</td>
              <td>String</td>
              <td></td>
            </tr>
            <tr>
              <td>message</td>
              <td>String</td>
              <td></td>
            </tr>
            <tr>
              <td>data[].product</td>
              <td>String</td>
              <td>Shortened product name, e.g., BTC</td>
            </tr>
            <tr>
              <td>data[].feeAmount</td>
              <td>String</td>
              <td>The amount of any fee that was charged</td>
            </tr>
            <tr>
              <td>data[].feeType</td>
              <td>String</td>
              <td>
                The calculation type for the fee. One of FlatRate or Percentage
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Response Code */}
      <div className="code-display">
        <p> Example Response</p>
        <pre>
          <code>
            {`{
    "code": "0000",
    "message": "SUCCESS",
    "data": [
        {
            "product": "BTC",
            "feeAmount": "10.00000000",
            "feeType": "FlatRate"
        }
    ]
}`}
          </code>
        </pre>
      </div>
    </div>
  );
};

export default ProductFeeTier;
