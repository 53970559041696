import React from "react";
import ReactDOM from "react-dom";
import './global.css'
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import './assets/fonts/Graphik TH Bold.ttf';
import './assets/fonts/Graphik TH Medium.ttf';
import './assets/fonts/Graphik TH Regular.ttf';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
