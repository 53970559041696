const CreateDeposit = () => {
  return (
    <div id="create-deposit-fiat" className="hashSection">
      <h1>Deposit Fiat</h1>
      <p className="api-path">POST /api/v1/digital-asset/deposit/fiat/create</p>
      <p>
        <strong>Permission</strong>: Deposit
      </p>
      <p>Create a new deposit fiat ticket</p>
      <div className="divider" />

      <h3>Request Body</h3>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>Field</th>
              <th>Mandatory</th>
              <th>Data type</th>
              <th style={{ minWidth: 400 }}>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>product</td>
              <td>Mandatory</td>
              <td>String</td>
              <td>Shortened product name, e.g., THB</td>
            </tr>
            <tr>
              <td>amount</td>
              <td>Mandatory</td>
              <td>String</td>
              <td>Unit amount of the product, e.g., 100 or 116.25.</td>
            </tr>
            <tr>
              <td>comments</td>
              <td>Optional</td>
              <td>String</td>
              <td>Any comments appended to the ticket.</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Request Code */}
      <div className="code-display">
        <p> Example Request</p>
        <pre>
          <code>
            {`{
    "product": THB,
    "amount": "10.00000000",
    "comments": null,
}
`}
          </code>
        </pre>
      </div>

      <h3>Response</h3>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>Field</th>
              <th>Data type</th>
              <th style={{ minWidth: 400 }}>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>code</td>
              <td>String</td>
              <td></td>
            </tr>
            <tr>
              <td>message</td>
              <td>String</td>
              <td></td>
            </tr>
            <tr>
              <td>datap[].requestCode</td>
              <td>String</td>
              <td>
                The globally unique ID (GUID) that identifies the specific
                deposit that is being created
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Response Code */}
      <div className="code-display">
        <p> Example Response</p>
        <pre>
          <code>
            {`{
  "status": "0000",
  "message": "success",
  "data": [
    {
        "requestCode": "866f21fe-3461-41d1-91aa-5689bc38503f"
    }
  ]
}`}
          </code>
        </pre>
      </div>
    </div>
  );
};
export default CreateDeposit;
