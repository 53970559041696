const AllWithdrawalTickets = () => {
  return (
    <div id="all-withdrawal-tickets" className="hashSection">
      <h1>Get All Withdrawal Tickets</h1>
      <p className="api-path">
        POST /api/v1/digital-asset/withdraw/all/inquiry
      </p>
      <p>
        <strong>Permission</strong>: Read, Withdraw
      </p>
      <p>Returns a limited array of withdrawal tickets</p>
      <div className="divider" />

      <h3>Request Body</h3>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>Field</th>
              <th>Mandatory</th>
              <th>Data type</th>
              <th style={{ minWidth: 400 }}>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>startIndex</td>
              <td>Optional</td>
              <td>Integer</td>
              <td>
                A pointer into the stream of withdrawal tickets. The oldest
                ticket is ticket 0. The default value for StartIndex is 0.
              </td>
            </tr>
            <tr>
              <td>depth</td>
              <td>Optional</td>
              <td>Integer</td>
              <td>
                The count of withdrawal transactions to be returned. Defaults to
                200.
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Request Code */}
      <div className="code-display">
        <p> Example Request</p>
        <pre>
          <code>
            {`{
    "startIndex": 0,
    "depth": 100
}
`}
          </code>
        </pre>
      </div>

      <h3>Response</h3>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>Field</th>
              <th>Data type</th>
              <th style={{ minWidth: 400 }}>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>code</td>
              <td>String</td>
              <td></td>
            </tr>
            <tr>
              <td>message</td>
              <td>String</td>
              <td></td>
            </tr>
            <tr>
              <td>data[].product</td>
              <td>String</td>
              <td>Shortened product name, e.g., BTC.</td>
            </tr>
            <tr>
              <td>data[].amount</td>
              <td>String</td>
              <td>Unit amount of the product, e.g., 100 or 116.25.</td>
            </tr>
            <tr>
              <td>data[].templateFormType</td>
              <td>String</td>
              <td>
                The name of the template being used. Templates vary from Account
                Provider to Account Provider, e.g., ToExternalBitcoinAddress
              </td>
            </tr>
            <tr>
              <td>data[].templateForm</td>
              <td>JSON string object</td>
              <td>
                The contents of the template.The Response returns information as
                a string.
              </td>
            </tr>
            <tr>
              <td>data[].templateForm.comment</td>
              <td>String</td>
              <td>Comment in the withdrawal transaction</td>
            </tr>
            <tr>
              <td>data[].templateForm.externalAddress</td>
              <td>String</td>
              <td>wallet address</td>
            </tr>
            <tr>
              <td>data[].templateForm.destinationTagNumber</td>
              <td>String</td>
              <td>Destination tag or Memo</td>
            </tr>
            <tr>
              <td>data[].templateForm.amount</td>
              <td>String</td>
              <td>Unit amount of the product, e.g., 100 or 116.25.</td>
            </tr>
            <tr>
              <td>data[].requestCode</td>
              <td>String</td>
              <td>
                A globally unique identifier (GUID) that identifies this
                specific withdrawal.
              </td>
            </tr>
            <tr>
              <td>data[].requestIP</td>
              <td>String</td>
              <td>The IP address from which the withdrawal was initiated</td>
            </tr>
            <tr>
              <td>data[].ticketStatus</td>
              <td>String</td>
              <td>
                Withdraw ticket statuses
                <br />
                <span>
                  <b>Processing</b> (ticket is being processed)
                </span>
                <br />
                <span>
                  <b>Rejected</b> (ticket is rejected)
                </span>
                <br />
                <span>
                  <b>Canceled</b> (ticket canceled by user)
                </span>
                <br />
                <span>
                  <b>PendingEmail</b> (pending email confirmation): The
                  "PendingEmail" status is valid only for cryptocurrency
                  withdrawals.
                </span>
                <br />
                <span>
                  <b>FullyProcessed</b> (ticket has been concluded)
                </span>
              </td>
            </tr>
            <tr>
              <td>data[].feeAmount</td>
              <td>String</td>
              <td>
                The unit and fractional quantity of the product or asset that
                was deposited. For example 2.5 BitCoin or 2018.17 US Dollars.
              </td>
            </tr>
            <tr>
              <td>data[].ticketId</td>
              <td>Long Integer</td>
              <td>The number of the ticket</td>
            </tr>
            <tr>
              <td>data[].createdAt</td>
              <td>String</td>
              <td>
                The date and time when the withdraw ticket was created,
                formatted as YYYY-MM-DDTHH:mm:ss.sssZ.
              </td>
            </tr>
            <tr>
              <td>data[].updatedAt</td>
              <td>String</td>
              <td>
                The date and time when the withdraw ticket was last updated,
                formatted as YYYY-MM-DDTHH:mm:ss.sssZ.
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Response Code */}
      <div className="code-display">
        <p> Example Response</p>
        <pre>
          <code>
            {`{
  "code": "0000",
  "message": "SUCCESS",
  "data": [
    {
      "product": "Bitcoin",
      "amount": "0.01000000",
      "templateFormType": "ToExternalBitcoinAddress",
      "templateForm": {
        "comment": "",
        "address": "tb1qafte2vv93wrkcnja0806w9yaa4m295txfafdpy",
        "destinationTagNumber": null,
        "amount": "0.01000000"
      },
      "requestCode": "138f94c1-b96b-4dbf-a904-8c5209c339eb",
      "requestIP": "49.228.227.220",
      "ticketStatus": "Processing",
      "feeAmount": "0.00000000",
      "ticketId": 25934,
      "createdAt": "2022-12-23T08:33:21.834Z",
      "updatedAt": "2022-12-23T08:33:35.308Z"
    },
    {
      "product": "AAVE1",
      "amount": "0.01000000",
      "templateFormType": "ToExternalEthereumAddress",
      "templateForm": {
        "comment": "",
        "address": "0x866549a90743a340d0a26d66F34e220Dd5Da4CB1",
        "destinationTagNumber": null,
        "amount": "0.00100000"
      },
      "requestCode": "e810752c-8431-89cc-ded0-ee18a5c7bf26",
      "requestIP": "125.26.254.78",
      "ticketStatus": "Processing",
      "feeAmount": "0.00000000",
      "ticketId": 25933,
      "createdAt": "2022-12-23T07:22:36.756Z",
      "updatedAt": "2022-12-23T07:25:34.365Z"
    }
  ]
}`}
          </code>
        </pre>
      </div>
    </div>
  );
};
export default AllWithdrawalTickets;
