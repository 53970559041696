const DepositTicket = () => {
  return (
    <div id="deposit-ticket" className="hashSection">
      <h1>Get Deposit Ticket</h1>
      <p className="api-path">POST /api/v1/digital-asset/deposit/inquiry</p>
      <p>
        <strong>Permission</strong>: Read, Deposit
      </p>
      <p>Returns deposit information of a specific account</p>
      <div className="divider" />

      <h3>Request Body</h3>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>Field</th>
              <th>Mandatory</th>
              <th>Data type</th>
              <th style={{ minWidth: 400 }}>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>requestCode</td>
              <td>Conditional Mandatory</td>
              <td>String</td>
              <td>
                A globally unique alphanumeric string (GUID) assigned by the
                system that identifies this specific deposit.
              </td>
            </tr>
            <tr>
              <td>ticketId</td>
              <td>Conditional Mandatory</td>
              <td>Long integer</td>
              <td>The ID of the specific deposit ticket.</td>
            </tr>
            <tr>
              <td>txHash</td>
              <td>Conditional Mandatory</td>
              <td>String</td>
              <td>
                a unique identifier, similar to a receipt, that serves as proof
                that a transaction was validated and added to the blockchain
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Request Code */}
      <div className="code-display">
        <p> Example Request</p>
        <pre>
          <code>
            {`{
    "requestCode": "e810752c-8431-89cc-ded0-ee18a5c7bf26",
    "ticketId": 25933,
    "txHash": "e810752c84319cclded0ee18a5c7bf26"
}
`}
          </code>
        </pre>
      </div>

      <h3>Response</h3>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>Field</th>
              <th>Data type</th>
              <th style={{ minWidth: 400 }}>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>code</td>
              <td>String</td>
              <td></td>
            </tr>
            <tr>
              <td>message</td>
              <td>String</td>
              <td></td>
            </tr>
            <tr>
              <td>data.ticketId</td>
              <td>Long Integer</td>
              <td>The number of the ticket</td>
            </tr>
            <tr>
              <td>data.requestCode</td>
              <td>String</td>
              <td>
                A globally unique alphanumeric string (GUID) assigned by the
                system that identifies this specific deposit.
              </td>
            </tr>
            <tr>
              <td>data.product</td>
              <td>String</td>
              <td>Shortened product name, e.g., BTC</td>
            </tr>
            <tr>
              <td>data.amount</td>
              <td>String</td>
              <td>Unit amount of the product, e.g., 100 or 116.25.</td>
            </tr>
            <tr>
              <td>data.status</td>
              <td>String</td>
              <td>
                Deposit ticket statuses
                <br />
                <span>
                  <b>Processing</b> (ticket is being processed)
                </span>
                <br />
                <span>
                  <b>Rejected</b> (ticket is rejected)
                </span>
                <br />
                <span>
                  <b>Canceled</b> (ticket canceled by user)
                </span>
                <br />
                <span>
                  <b>PendingEmail</b> (pending email confirmation): The
                  "PendingEmail" status is valid only for cryptocurrency
                  withdrawals.
                </span>
                <br />
                <span>
                  <b>FullyProcessed</b> (ticket has been concluded)
                </span>
              </td>
            </tr>
            <tr>
              <td>data.feeAmount</td>
              <td>String</td>
              <td>The deposit fee that is charged for making the deposit.</td>
            </tr>
            <tr>
              <td>data.createdAt</td>
              <td>String</td>
              <td>
                The date and time when the deposit ticket was created, formatted
                as YYYY-MM-DDTHH:mm:ss.sssZ.
              </td>
            </tr>
            <tr>
              <td>data.updatedAt</td>
              <td>String</td>
              <td>
                The date and time when the deposit ticket was last updated,
                formatted as YYYY-MM-DDTHH:mm:ss.sssZ.
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Response Code */}
      <div className="code-display">
        <p> Example Response</p>
        <pre>
          <code>
            {`{
    "status": "0000",
    "message": "success",
    "data":
        {
            "ticketId": 25933,
            "requestCode": "e810752c-8431-89cc-ded0-ee18a5c7bf26",
            "product": AAVE1,
            "amount": "10.00000000",
            "status": "Processing",
            "feeAmount": "0.00000000",
            "createdAt": "2023-01-01T00:00:00Z"
            "updatedAt": "2023-01-01T00:00:00Z"
        }
}`}
          </code>
        </pre>
      </div>
    </div>
  );
};
export default DepositTicket;
