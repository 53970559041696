const StandardErrorCodes = () => {
  return (
    <div id="standard-error-codes" className="hashSection">
      <h1>Standard Error Codes</h1>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>Http code</th>
              <th>Error code</th>
              <th>Error message</th>
              <th style={{ minWidth: 400 }}>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>200</td>
              <td>0000</td>
              <td>Success</td>
              <td>A successful response</td>
            </tr>
            <tr>
              <td>400</td>
              <td>4001</td>
              <td>Reject transaction</td>
              <td>The transaction is rejected</td>
            </tr>
            <tr>
              <td>403</td>
              <td>4002</td>
              <td>Invalid X-INVX-APIKEY</td>
              <td>X-API-Key is invalid</td>
            </tr>
            <tr>
              <td>403</td>
              <td>4003</td>
              <td>Invalid IP Whitelist</td>
              <td>
                The IP address does not have the permission to access the API
                key
              </td>
            </tr>
            <tr>
              <td>403</td>
              <td>4004</td>
              <td>X-INVX-APIKEY Permission Denied</td>
              <td>
                The API key does not have the permission to call the endpoint
              </td>
            </tr>
            <tr>
              <td>403</td>
              <td>4005</td>
              <td>Invalid X-INVX-SIGNATURE</td>
              <td>Signed signature is incorect</td>
            </tr>
            <tr>
              <td>403</td>
              <td>4007</td>
              <td>Invalid Request</td>
              <td>Request input does not satisfty</td>
            </tr>
            <tr>
              <td>403</td>
              <td>4008</td>
              <td>Invalid X-INVX-REQUEST-UID</td>
              <td>Invalid X-INVX-REQUEST-UID</td>
            </tr>
            <tr>
              <td>403</td>
              <td>4010</td>
              <td>Invalid timestamp format</td>
              <td>
                Timestamp format should be UTC in milliseconds. Example
                "1567755304968"
              </td>
            </tr>
            <tr>
              <td>403</td>
              <td>4011</td>
              <td>
                Departure timestamp is more than 150 seconds from current server
                time
              </td>
              <td>
                If timestamp is more than 150 seconds from current server time,
                it will not allow to make the request{" "}
              </td>
            </tr>
            <tr>
              <td>403</td>
              <td>4012</td>
              <td>Invalid Signature</td>
              <td>Invalid Signature</td>
            </tr>
            <tr>
              <td>400</td>
              <td>4013</td>
              <td>{`Invalid {error message}`}</td>
              <td>Generic error response applying to different cases</td>
            </tr>
            <tr>
              <td>400</td>
              <td>4014</td>
              <td>LimitPrice must not be null</td>
              <td>Limit price is required</td>
            </tr>
            <tr>
              <td>400</td>
              <td>4015</td>
              <td>Quantity or value must not be null</td>
              <td>Quantity or value is required</td>
            </tr>
            <tr>
              <td>400</td>
              <td>4016</td>
              <td>Invalid datetime format</td>
              <td>Datetime format should be YYYY-MM-DDTHH:mm:ss.sssZ</td>
            </tr>
            <tr>
              <td>400</td>
              <td>4017</td>
              <td>Below minimum amount</td>
              <td>The amount must be higher than minimum amount</td>
            </tr>
            <tr>
              <td>400</td>
              <td>4018</td>
              <td>Exceed maximum amount</td>
              <td>The amount must not exceed maximum amount</td>
            </tr>
            <tr>
              <td>400</td>
              <td>4019</td>
              <td>Insufficient Balance</td>
              <td>Insufficient Balance</td>
            </tr>
            <tr>
              <td>400</td>
              <td>4020</td>
              <td>Parameter is missing</td>
              <td>Parameter is missing</td>
            </tr>
            <tr>
              <td>400</td>
              <td>4021</td>
              <td>{`The parameter {filled name} must be of type {data type}`}</td>
              <td>Data type is incorrect</td>
            </tr>
            <tr>
              <td>400</td>
              <td>4022</td>
              <td>Input must not be null</td>
              <td>Input must not be null</td>
            </tr>
            <tr>
              <td>404</td>
              <td>4041</td>
              <td>Data not found</td>
              <td>General data not found</td>
            </tr>
            <tr>
              <td>404</td>
              <td>4042</td>
              <td>Symbol not found</td>
              <td>Incorrect symbol format or it is not in the list</td>
            </tr>
            <tr>
              <td>404</td>
              <td>4043</td>
              <td>Product not found</td>
              <td>Product not found</td>
            </tr>
            <tr>
              <td>500</td>
              <td>9100</td>
              <td>Internal Server Error</td>
              <td>Internal Server Error</td>
            </tr>
            <tr>
              <td>500</td>
              <td>9999</td>
              <td>External Service Error</td>
              <td>External Service Error</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default StandardErrorCodes;
