const CreateWithdraw = () => {
  return (
    <div id="create-withdraw-Fiat" className="hashSection">
      <h1>Withdraw Fiat</h1>
      <p className="api-path">
        POST /api/v1/digital-asset/withdraw/fiat/create
      </p>
      <p>
        <strong>Permission</strong>: Withdraw
      </p>
      <p>Create a new withdrawal fiat ticket</p>
      <div className="divider" />

      <h3>Request Body</h3>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>Field</th>
              <th>Mandatory</th>
              <th>Data type</th>
              <th style={{ minWidth: 400 }}>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>product</td>
              <td>Mandatory</td>
              <td>String</td>
              <td>Shortened product name, e.g., THB</td>
            </tr>
            <tr>
              <td>amount</td>
              <td>Mandatory</td>
              <td>Decimal</td>
              <td>Unit amount of the product, e.g., 100 or 116.25.</td>
            </tr>
            <tr>
              <td>comment</td>
              <td>Optional</td>
              <td>String</td>
              <td>Any comments appended to the withdraw ticket.</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Request Code */}
      <div className="code-display">
        <p> Example Request</p>
        <pre>
          <code>
            {`{
    "product": "THB", 
    "amount": 100.00, 
    "comments": null,
}
`}
          </code>
        </pre>
      </div>

      <h3>Response</h3>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>Field</th>
              <th>Data type</th>
              <th style={{ minWidth: 400 }}>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>code</td>
              <td>String</td>
              <td></td>
            </tr>
            <tr>
              <td>message</td>
              <td>String</td>
              <td></td>
            </tr>
            <tr>
              <td>data.requestCode</td>
              <td>String</td>
              <td>
                The globally unique ID (GUID) that identifies the specific
                withdraw that is being created
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Response Code */}
      <div className="code-display">
        <p> Example Response</p>
        <pre>
          <code>
            {`{
    "status": "0000",
    "message": "success",
    "data": {
            "requestCode": "19502103-30f2-2cb5-ac1e-1d3db9a39a51"
    }
}`}
          </code>
        </pre>
      </div>
    </div>
  );
};
export default CreateWithdraw;
