const TickerActivity = () => {
  return (
    <div id="subscribe-ticker" className="hashSection">
      <h1>Subscribe Ticker</h1>
      <p className="api-path">POST /api/v1/digital-asset/ticker/subscribe</p>
      <p>
        <strong>Permission</strong>: Read, Trading
      </p>
      <p>
        Retrieve ticker market data feed for a particular symbol in 1 minute
        interval, including trading activity and price movements associated with
        the specific symbol
      </p>
      <div className="divider" />

      <h3>Request Body</h3>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>Field</th>
              <th>Mandatory</th>
              <th>Data type</th>
              <th style={{ minWidth: 400 }}>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>symbol</td>
              <td>Mandatory</td>
              <td>String</td>
              <td>
                The symbol parameter specifies the desired ticker market data to
                track, such as BTCTHB
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Request Code */}
      <div className="code-display">
        <p> Example Request</p>
        <pre>
          <code>
            {`{
    "symbol": BTCTHB 
}
`}
          </code>
        </pre>
      </div>

      <h3>Response</h3>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>Field</th>
              <th>Data type</th>
              <th style={{ minWidth: 400 }}>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>code</td>
              <td>String</td>
              <td></td>
            </tr>
            <tr>
              <td>message</td>
              <td>String</td>
              <td></td>
            </tr>
            <tr>
              <td>data[].dateTime</td>
              <td>String</td>
              <td>
                The specific date and time that the snapshot was captured or the
                event occurred
              </td>
            </tr>
            <tr>
              <td>data[].high</td>
              <td>String</td>
              <td>
                The highest recorded price for the symbol during a specific
                interval
              </td>
            </tr>
            <tr>
              <td>data[].low</td>
              <td>String</td>
              <td>
                The lowest recorded price for the symbol during a specific
                interval
              </td>
            </tr>
            <tr>
              <td>data[].open</td>
              <td>String</td>
              <td>
                The price at which the symbol started trading at the beginning
                of a specific interval
              </td>
            </tr>
            <tr>
              <td>data[].close</td>
              <td>String</td>
              <td>
                The price at which the symbol finished trading at the end of a
                specific interval
              </td>
            </tr>
            <tr>
              <td>data[].volume</td>
              <td>String</td>
              <td>
                The total quantity of the symbol traded during a specific
                interval
              </td>
            </tr>
            <tr>
              <td>data[].insideBidPrice</td>
              <td>String</td>
              <td>
                The highest price that a buyer is willing to pay for the symbol
                at a given moment
              </td>
            </tr>
            <tr>
              <td>data[].insideaskPrice</td>
              <td>String</td>
              <td>
                The lowest price at which a seller is willing to sell the symbol
                at a given moment
              </td>
            </tr>
            <tr>
              <td>data[].symbol</td>
              <td>String</td>
              <td>A specified symbol being tracked</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Response Code */}
      <div className="code-display">
        <p> Example Response</p>
        <pre>
          <code>
            {`{
    "code": "0000",
    "message": "SUCCESS",
    "data": [
        {
            "dateTime": "2023-06-14T08:47:00.000Z",
            "high": "896789.99000000",
            "low": "896789.99000000",
            "open": "896789.99000000",
            "close": "896789.99000000",
            "volume": "0.00000000",
            "insideBidPrice": "892910.64000000",
            "insideAskPrice": "896789.99000000",
            "symbol": "BTCTHB"
        }
  ]
}
`}
          </code>
        </pre>
      </div>
    </div>
  );
};

export default TickerActivity;
