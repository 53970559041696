const CancelOrder = () => {
  return (
    <div id="cancel-order" className="hashSection">
      <h1>Cancel Order</h1>
      <p className="api-path">POST /api/v1/digital-asset/order/cancel</p>
      <p>
        <strong>Permission</strong>: Trading
      </p>
      <p>
        To cancel an open order that has been placed but is not yet fully
        executed.
      </p>
      <div className="divider" />

      <h3>Request Body</h3>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>Field</th>
              <th>Mandatory</th>
              <th>Data type</th>
              <th style={{ minWidth: 400 }}>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>clientOrderId</td>
              <td>Conditional Mandatory</td>
              <td>Long Integer</td>
              <td>
                A user-assigned ID for the order, similar to a purchase order
                number used by companies, which helps in identifying and
                tracking future updates or changes
              </td>
            </tr>
            <tr>
              <td>orderId</td>
              <td>Conditional Mandatory</td>
              <td>Long Integer</td>
              <td>A unique identifier assigned to an order</td>
            </tr>
          </tbody>
        </table>

        <p style={{ width: "100%" }}>
          <b>*Note</b> - Both clientOrderId and orderId should be as optional but
          mandatory to provide one of them.
        </p>
      </div>

      {/* Request Code */}
      <div className="code-display">
        <p> Example Request</p>
        <pre>
          <code>
            {`{
    "clientOrderId": 10300,
    "orderId": null 
}
`}
          </code>
        </pre>
      </div>

      <h3>Response</h3>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>Field</th>
              <th>Data type</th>
              <th style={{ minWidth: 400 }}>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>code</td>
              <td>String</td>
              <td></td>
            </tr>
            <tr>
              <td>message</td>
              <td>String</td>
              <td></td>
            </tr>
            <tr>
              <td>data.detail</td>
              <td>String</td>
              <td>Detail response of cancel order (if any)</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Response Code */}
      <div className="code-display">
        <p> Example Response</p>
        <pre>
          <code>
            {`{
    "code": "0000",
    "message": "SUCCESS"
}
`}
          </code>
        </pre>

        <p> Example Response</p>
        <pre>
          <code>
            {`{
    "code": "1001",
    "message": "Reject transaction",
    "data": {
        "detail": "Resource Not Found"
    }
}
`}
          </code>
        </pre>
      </div>
    </div>
  );
};

export default CancelOrder;
