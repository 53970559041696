import { HashLink as Link } from "react-router-hash-link";
import * as HashKey from "../../constants/hash-key";

const getItem = (label, key, children) => {
  return { key, children, label };
};

export const menuItems = [
  getItem("Introduction", "sub1", [
    getItem(
      <Link to={HashKey.GETTING_STARTED}>Getting Started</Link>,
      HashKey.GETTING_STARTED
    ),
    getItem(
      <Link to={HashKey.APIKEY_SETUP}>API Key Setup</Link>,
      HashKey.APIKEY_SETUP
    ),
    getItem(
      <Link to={HashKey.CREATING_REQUEST}>Authenticate Message</Link>,
      HashKey.CREATING_REQUEST
    ),
    getItem(
      <Link to={HashKey.STANDARD_ERROR_CODES}>Standard Error Codes</Link>,
      HashKey.STANDARD_ERROR_CODES
    ),
  ]),
  getItem("General Info", "sub2", [
    getItem(
      <Link to={HashKey.REST_API_OVERVIEW}>Rest API Overview</Link>,
      HashKey.REST_API_OVERVIEW
    ),
    getItem(
      <Link to={HashKey.API_PERMISSIONS}>API Permissions</Link>,
      HashKey.API_PERMISSIONS
    ),
    // getItem(
    //   <Link to={HashKey.API_RATE_LIMITS}>API Rate Limits</Link>,
    //   HashKey.API_RATE_LIMITS
    // ),
  ]),
  getItem("Market Data", "sub3", [
    getItem(
      <Link to={HashKey.ORDER_BOOK}>Subscribe Level 2</Link>,
      HashKey.ORDER_BOOK
    ),
    getItem(
      <Link to={HashKey.TICKER_ACTIVITY}>Subscribe Ticker</Link>,
      HashKey.TICKER_ACTIVITY
    ),
  ]),
  getItem("Order Management", "sub4", [
    getItem(
      <Link to={HashKey.SEND_ORDER}>Send Order</Link>,
      HashKey.SEND_ORDER
    ),
    getItem(
      <Link to={HashKey.CANCEL_ORDER}>Cancel Order</Link>,
      HashKey.CANCEL_ORDER
    ),
    getItem(
      <Link to={HashKey.OPEN_ORDER}>Get Open Orders</Link>,
      HashKey.OPEN_ORDER
    ),
    getItem(
      <Link to={HashKey.ORDER_HISTORY}>Get Order History</Link>,
      HashKey.ORDER_HISTORY
    ),
    getItem(
      <Link to={HashKey.ESTIMATED_ORDER_FEE}>Get Estimate Fee</Link>,
      HashKey.ESTIMATED_ORDER_FEE
    ),
  ]),
  getItem("Account Management", "sub5", [
    getItem(
      <Link to={HashKey.ACCOUNT_TRADE_HISTORY}>Get Account Trade</Link>,
      HashKey.ACCOUNT_TRADE_HISTORY
    ),
    getItem(
      <Link to={HashKey.ACCOUNT_BALANCE}>Get Account Balance</Link>,
      HashKey.ACCOUNT_BALANCE
    ),
  ]),
  getItem("Transfers", "sub6", [
    getItem(
      <Link to={HashKey.CREATE_DEPOSIT_FIAT}>Deposits Fiat</Link>,
      HashKey.CREATE_DEPOSIT_FIAT
    ),
    getItem(
      <Link to={HashKey.DEPOSIT_TICKET}>Get Deposit Ticket</Link>,
      HashKey.DEPOSIT_TICKET
    ),
    getItem(
      <Link to={HashKey.ALL_DEPOSIT_TICKETS}>Get All Deposit Tickets</Link>,
      HashKey.ALL_DEPOSIT_TICKETS
    ),
    getItem(
      <Link to={HashKey.DEPOSIT_WALLET_ADDRESS}>
        Get Deposit Wallet Address
      </Link>,
      HashKey.DEPOSIT_WALLET_ADDRESS
    ),
    getItem(
      <Link to={HashKey.WITHDRAW_WALLET_ADDRESS}>
        Get Withdraw Wallet Address
      </Link>,
      HashKey.WITHDRAW_WALLET_ADDRESS
    ),
    getItem(
      <Link to={HashKey.CREATE_WITHDRAW_FIAT}>Withdraws Fiat</Link>,
      HashKey.CREATE_WITHDRAW_FIAT
    ),
    getItem(
      <Link to={HashKey.CREATE_WITHDRAW_CRYPTO}>Withdraws Crypto</Link>,
      HashKey.CREATE_WITHDRAW_CRYPTO
    ),
    getItem(
      <Link to={HashKey.WITHDRAWAL_FEE}>Get Withdrawal Fee</Link>,
      HashKey.WITHDRAWAL_FEE
    ),
    getItem(
      <Link to={HashKey.CANCEL_WITHDRAW}>Cancel Withdraw</Link>,
      HashKey.CANCEL_WITHDRAW
    ),
    getItem(
      <Link to={HashKey.WITHDRAWAL_TICKET}>Get Withdrawal Ticket</Link>,
      HashKey.WITHDRAWAL_TICKET
    ),
    getItem(
      <Link to={HashKey.ALL_WITHDRAWAL_TICKETS}>
        Get All Withdrawal Tickets
      </Link>,
      HashKey.ALL_WITHDRAWAL_TICKETS
    ),
  ]),
  getItem("Product Information", "sub7", [
    getItem(<Link to={HashKey.PRODUCTS}>Get Products</Link>, HashKey.PRODUCTS),
    getItem(<Link to={HashKey.SYMBOLS}>Get Symbols</Link>, HashKey.SYMBOLS),
    getItem(
      <Link to={HashKey.PRODUCT_FEE_TIER}>Get Product Fee Tier</Link>,
      HashKey.PRODUCT_FEE_TIER
    ),
    getItem(
      <Link to={HashKey.SYMBOL_FEE_TIER}>Get Symbol Fee Tier</Link>,
      HashKey.SYMBOL_FEE_TIER
    ),
  ]),
];
