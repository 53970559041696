import * as HashKey from "../constants/hash-key";
export function getActiveKeySubMenu(hash) {
  switch (hash) {
    case HashKey.GETTING_STARTED:
    case HashKey.APIKEY_SETUP:
    case HashKey.CREATING_REQUEST:
    case HashKey.STANDARD_ERROR_CODES:
      return ["sub1"];
    case HashKey.REST_API_OVERVIEW:
    case HashKey.API_PERMISSIONS:
      // case HashKey.API_RATE_LIMITS:
      return ["sub2"];
    case HashKey.ORDER_BOOK:
    case HashKey.TICKER_ACTIVITY:
      return ["sub3"];
    case HashKey.SEND_ORDER:
    case HashKey.CANCEL_ORDER:
    case HashKey.OPEN_ORDER:
    case HashKey.ORDER_HISTORY:
    case HashKey.ESTIMATED_ORDER_FEE:
      return ["sub4"];
    case HashKey.ACCOUNT_TRADE_HISTORY:
    case HashKey.ACCOUNT_BALANCE:
      return ["sub5"];
    case HashKey.CREATE_DEPOSIT_FIAT:
    case HashKey.DEPOSIT_TICKET:
    case HashKey.ALL_DEPOSIT_TICKETS:
    case HashKey.DEPOSIT_WALLET_ADDRESS:
    case HashKey.WITHDRAW_WALLET_ADDRESS:
    case HashKey.CREATE_WITHDRAW_FIAT:
    case HashKey.CREATE_WITHDRAW_CRYPTO:
    case HashKey.WITHDRAWAL_FEE:
    case HashKey.CANCEL_WITHDRAW:
    case HashKey.WITHDRAWAL_TICKET:
    case HashKey.ALL_WITHDRAWAL_TICKETS:
      return ["sub6"];
    case HashKey.PRODUCTS:
    case HashKey.SYMBOLS:
    case HashKey.PRODUCT_FEE_TIER:
    case HashKey.SYMBOL_FEE_TIER:
      return ["sub7"];
    default:
      return ["sub1"];
  }
}
