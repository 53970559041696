export const GETTING_STARTED = "#getting-started";
export const APIKEY_SETUP = "#apikey-setup";
export const CREATING_REQUEST = "#authenticate-message";
export const STANDARD_ERROR_CODES = "#standard-error-codes";
export const REST_API_OVERVIEW = "#rest-api-overview";
export const API_PERMISSIONS = "#api-permissions";
export const API_RATE_LIMITS = "#api-rate-limits";
export const ORDER_BOOK = "#subscribe-level-2";
export const TICKER_ACTIVITY  = "#subscribe-ticker";
export const SEND_ORDER = "#create-order";
export const CANCEL_ORDER  = "#cancel-order";
export const OPEN_ORDER  = "#open-orders";
export const ORDER_HISTORY  = "#order-history";
export const ESTIMATED_ORDER_FEE  = "#estimated-order-fee";
export const ACCOUNT_TRADE_HISTORY  = "#account-trade-history";
export const ACCOUNT_BALANCE  = "#account-balance";
export const CREATE_DEPOSIT_FIAT  = "#create-deposit-fiat";
export const DEPOSIT_TICKET   = "#deposit-ticket";
export const ALL_DEPOSIT_TICKETS   = "#all-deposit-tickets";
export const DEPOSIT_WALLET_ADDRESS   = "#deposit-wallet-address";
export const WITHDRAW_WALLET_ADDRESS   = "#withdraw-wallet-address";
export const CREATE_WITHDRAW_FIAT   = "#create-withdraw-Fiat";
export const CREATE_WITHDRAW_CRYPTO   = "#create-withdraw-crypto";
export const WITHDRAWAL_FEE   = "#withdrawal-fee";
export const CANCEL_WITHDRAW   = "#cancel-withdraw";
export const WITHDRAWAL_TICKET   = "#withdrawal-ticket";
export const ALL_WITHDRAWAL_TICKETS   = "#all-withdrawal-tickets";
export const PRODUCTS   = "#products";
export const SYMBOLS   = "#symbols";
export const PRODUCT_FEE_TIER   = "#product-fee-tier";
export const SYMBOL_FEE_TIER   = "#symbol-fee-tier";