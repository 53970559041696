const Symbols = () => {
  return (
    <div id="symbols" className="hashSection">
      <h1>Get Symbols</h1>
      <p className="api-path">GET /api/v1/digital-asset/symbols</p>
      <p>
        <strong>Permission</strong>: Read
      </p>
      <p>
        Retrieves a list of instruments available on the exchange. An instrument
        is a pair of exchanged products
      </p>
      <div className="divider" />

      <h3>Response</h3>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>Field</th>
              <th>Data type</th>
              <th style={{ minWidth: 400 }}>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>code</td>
              <td>String</td>
              <td></td>
            </tr>
            <tr>
              <td>message</td>
              <td>String</td>
              <td></td>
            </tr>
            <tr>
              <td>data[].symbol</td>
              <td>String</td>
              <td>Trading symbol, e.g., BTCTHB.</td>
            </tr>
            <tr>
              <td>data[].quantityIncrement</td>
              <td>String</td>
              <td>
                The smallest tradeable increment of the symbol. For example, for
                BTCUSD, the quantity increment might be 0.0005, but for ETHUSD,
                the quantity increment might be 50.
              </td>
            </tr>
            <tr>
              <td>data[].priceIncrement</td>
              <td>String</td>
              <td>
                The amount by which the symbol can rise or fall in the market.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      
      {/* Response Code */}
      <div className="code-display">
        <p> Example Response</p>
        <pre>
          <code>
            {`{
    "code": "0000",
    "message": "SUCCESS",
    "data": [
        {
            "symbol": "BTCTHB",
            "quantityIncrement": "0.00001000",
            "priceIncrement": "0.01000000"
        }
    ]
}
`}
          </code>
        </pre>
      </div>
    </div>
  );
};

export default Symbols;
