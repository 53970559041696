const CreateWithdrawCrypto = () => {
  return (
    <div id="create-withdraw-crypto" className="hashSection">
      <h1>Withdraw Crypto</h1>
      <p className="api-path">
        POST /api/v1/digital-asset/withdraw/crypto/create
      </p>
      <p>
        <strong>Permission</strong>: Withdraw
      </p>
      <p>Initiates a withdrawal of cryptocurrency from an account. </p>
      <div className="divider" />

      <h3>Request Body</h3>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>Field</th>
              <th>Mandatory</th>
              <th>Data type</th>
              <th style={{ minWidth: 400 }}>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>product</td>
              <td>Mandatory</td>
              <td>String</td>
              <td>
                Shortened product name of the product being withdrawn, e.g.,
                BTC.
              </td>
            </tr>
            <tr>
              <td>amount</td>
              <td>Mandatory</td>
              <td>Decimal</td>
              <td>
                The number of units of the product being withdrawn (not the
                asset's monetary value), eg. 100 or 116.25.
              </td>
            </tr>
            <tr>
              <td>address</td>
              <td>Mandatory</td>
              <td>String</td>
              <td>
                The specific destination address where a user intends to
                transfer or send their cryptocurrency from their account to an
                external wallet or another account
              </td>
            </tr>
            <tr>
              <td>networkId</td>
              <td>Mandatory</td>
              <td>Integer</td>
              <td>
                ID of the network that is used to initiate a withdrawal request.
                The available network Id can be retrieved from
                WithdrawWalletAddress
              </td>
            </tr>
            <tr>
              <td>comment</td>
              <td>Optional</td>
              <td>String</td>
              <td>Any comment appended to the ticket</td>
            </tr>
            <tr>
              <td>destinationTagNumber</td>
              <td>Optional</td>
              <td>String</td>
              <td>
                The destination tag or memo is required when transferring
                cryptocurrencies like XRP (Ripple) and XLM (Stellar) to another
                wallet
              </td>
            </tr>
            <tr>
              <td>passcode</td>
              <td>Mandatory</td>
              <td>String</td>
              <td>An authentication code associated with user’s account</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Request Code */}
      <div className="code-display">
        <p> Example Request</p>
        <pre>
          <code>
            {`{
    "product": "BTC", 
    "amount": 500.00, 
    "address": "9090909090989", 
    "networkId" : 10, 
    "comment" : "", 
    "destinationTagNumber" : "3333", 
    "passcode" : "121212" 
}
`}
          </code>
        </pre>
      </div>

      <h3>Response</h3>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>Field</th>
              <th>Data type</th>
              <th style={{ minWidth: 400 }}>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>code</td>
              <td>String</td>
              <td></td>
            </tr>
            <tr>
              <td>message</td>
              <td>String</td>
              <td></td>
            </tr>
            <tr>
              <td>data.requestCode</td>
              <td>String</td>
              <td>
                The GUID assigned by the system to the withdraw ticket when
                create withdraw ticket
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Response Code */}
      <div className="code-display">
        <p> Example Response</p>
        <pre>
          <code>
            {`{
    "status": "0000",
    "message": "success",
    "data": {
            "requestCode": "19502103-30f2-2cb5-ac1e-1d3db9a39a51"
    }
}`}
          </code>
        </pre>
      </div>
    </div>
  );
};
export default CreateWithdrawCrypto;
