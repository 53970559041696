const ApiPermissions = () => {
  return (
    <div id="api-permissions" className="hashSection">
      <h1>API Permissions</h1>
      <p>
        The permission of each API key will determines the accessibility to
        endpoints. It can be specified during the generation process. All
        permission type will have the same API key authentication method
      </p>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>Permission</th>
              <th style={{ minWidth: 400 }}>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Trading</td>
              <td>
                Execute orders and retrieve trading data, including order book
                and ticker activity
              </td>
            </tr>
            <tr>
              <td>Deposit</td>
              <td>
                Initiate deposit request and retrieve relevant data information
              </td>
            </tr>
            <tr>
              <td>Withdraw</td>
              <td>
                Initiate withdraw request and retrieve relevant data information
              </td>
            </tr>
            <tr>
              <td>Read</td>
              <td>
                Retrieve various account data, including balances, transaction
                history, and more
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default ApiPermissions;
