const CancelWithdraw = () => {
  return (
    <div id="cancel-withdraw" className="hashSection">
      <h1>Cancel Withdraw</h1>
      <p className="api-path">POST /api/v1/digital-asset/withdraw/cancel</p>
      <p>
        <strong>Permission</strong>: Withdraw
      </p>
      <p>
        Cancel a pending withdrawal ticket that previously initiated request to
        withdraw fiat or cryptocurrenct from an account
      </p>
      <div className="divider" />

      <h3>Request Body</h3>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>Field</th>
              <th>Mandatory</th>
              <th>Data type</th>
              <th style={{ minWidth: 400 }}>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>requestCode</td>
              <td>Mandatory</td>
              <td>String</td>
              <td>
                A globally unique alphanumeric string (GUID) assigned by the
                system
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Request Code */}
      <div className="code-display">
        <p> Example Request</p>
        <pre>
          <code>
            {`{
      "requestCode" : "076bfac1-fd19-30fd-506f-c353eba37525"
}
`}
          </code>
        </pre>
      </div>

      <h3>Response</h3>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>Field</th>
              <th>Data type</th>
              <th style={{ minWidth: 400 }}>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>code</td>
              <td>String</td>
              <td></td>
            </tr>
            <tr>
              <td>message</td>
              <td>String</td>
              <td></td>
            </tr>
            <tr>
              <td>data.detail</td>
              <td>String</td>
              <td>The detail of the ticket that was being canceled</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Response Code */}
      <div className="code-display">
        <p> Example Response</p>
        <pre>
          <code>
            {`{
    "status": "0000",
    "message": "success",
    "data": {
        "detail": null
    }
}`}
          </code>
        </pre>
      </div>
    </div>
  );
};
export default CancelWithdraw;
