const CreatingRequest = () => {
  return (
    <div id="authenticate-message" className="hashSection">
      <h1>Authenticate Message</h1>
      <h3>Request Header</h3>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>Field</th>
              <th>Data type</th>
              <th>Length</th>
              <th>Mandatory</th>
              <th style={{ minWidth: 400 }}>Description</th>
              <th>Example</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>X-INVX-APIKEY</td>
              <td>String</td>
              <td>64</td>
              <td>M</td>
              <td>
                Key that InnovestX assign to each channel for authorization{" "}
                <b>(Length 64)</b>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>X-INVX-SIGNATURE</td>
              <td>String</td>
              <td></td>
              <td>M</td>
              <td>sha256.hmac(string_to_sign, api_secret)</td>
              <td></td>
            </tr>
            <tr>
              <td>X-INVX-REQUEST-UID</td>
              <td>String</td>
              <td>36</td>
              <td>M</td>
              <td>A unique identifier to track the current request call</td>
              <td>019d1bae-e2f1-42d9-b9e8-23d495dbe9f9</td>
            </tr>
            <tr>
              <td>X-INVX-TIMESTAMP</td>
              <td>String</td>
              <td>13</td>
              <td>M</td>
              <td>
                Request departure timestamp UTC in milliseconds. If timestamp is
                more than 150 seconds from current server time, it will not
                allow to make the request.
              </td>
              <td>1567755304968</td>
            </tr>
            <tr>
              <td>X-INVX-APIKEY</td>
              <td>String</td>
              <td>64</td>
              <td>M</td>
              <td>
                Key that InnovestX assign to each channel for authorization{" "}
                <b>(Length 64)</b>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>Accept-language</td>
              <td>String</td>
              <td></td>
              <td>O</td>
              <td>
                Values: <br />
                - EN (default) <br />- TH
              </td>
              <td></td>
            </tr>
            <tr>
              <td>Application-Reference</td>
              <td>String</td>
              <td>50</td>
              <td>O</td>
              <td>channel name</td>
              <td></td>
            </tr>
            <tr>
              <td>Content-Type</td>
              <td>String</td>
              <td>50</td>
              <td>M</td>
              <td>Content type JSON</td>
              <td>application/json</td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />

      <div className="code-display">
        <p> Example NodeJS</p>
        <pre>
          <code>
            {`const axios = require("axios");
const uuid = require("uuid");
const CryptoJS = require("crypto-js");

const requestUId = uuid.v4();
const public_key =
  "03e8b94ce5194678bb9b8938274ba437bc9fb653bf9b4e199ebbc8d51566b9cc";
const private_key = "b76487089ff240988542a61a9bbaacb5";
const contentType = "application/json";
const timestamp = new Date().getTime();
const host = "api-dev.innovestxonline.com";
const path = "/api/v1/digital-asset/orderbook/lvl2";
const query = "";
const method = "POST";

let body = {
  symbol: "ETHTHB",
};

const content_to_sign =
  public_key +
  method +
  host +
  path +
  query +
  contentType +
  requestUId +
  timestamp +
  JSON.stringify(body);

const signature = CryptoJS.enc.Hex.stringify(
  CryptoJS.HmacSHA256(content_to_sign, private_key)
);

axios({
  method: method,
  url: 'https://api.innovestxonline.com/api/v1/digital-asset/orderbook/lvl2',
  headers: {
    "Content-Type": contentType,
    "X-INVX-REQUEST-UID": requestUId,
    "X-INVX-TIMESTAMP": timestamp,
    "X-INVX-SIGNATURE": signature,
    "X-INVX-APIKEY": public_key,
  },
  data: JSON.stringify(body),
})
  .then((response) => {
    console.log(response.data);
  })
  .catch((error) => {
    console.error(error.message);
  });
`}
          </code>
        </pre>
      </div>
      <h3>Create Signature</h3>
      <p>
        The X-INVX-SIGNATURE header is generated by creating a sha256 HMAC
        object using the API secret key on the string : <br />
        <br />
        The following have to be combined into a single string:
        <br />
        <div style={{ marginLeft: 20 }}>
          X-INVX-APIKEY + <br />
          HTTP Verb + <br />
          url.host + <br />
          url.path + <br />
          url.query +
          <br />
          Content-Type + <br />
          X-INVX-REQUEST-UID + <br />
          X-INVX-TIMESTAMP + <br />
          request.body
        </div>
      </p>

      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th style={{ width: 160 }}>Field</th>
              <th style={{ minWidth: 400 }}>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>X-INVX-APIKEY</td>
              <td>Same as authorization header.</td>
            </tr>
            <tr>
              <td>HTTP Verb</td>
              <td>
                The HTTP (uppercase) verb.
                <br />
                <br />
                Example: "GET", "POST"
              </td>
            </tr>
            <tr>
              <td>url.host</td>
              <td>
                The hostname (lowercase), matching the HTTP "Host" request
                header field (including any port number). <br />
                <br />
                Example: "api.innovestxonline.com"
              </td>
            </tr>
            <tr>
              <td>url.path</td>
              <td>
                The HTTP request path with leading slash.
                <br />
                <br />
                Example: "/api/v1/digital-asset/order/send"
              </td>
            </tr>
            <tr>
              <td>url.query</td>
              <td>
                Any query parameters or empty string. This should be the exact
                string sent by the client, including urlencoding. <br />
                <br />
                Example: "?limit=100&sort=asc"
              </td>
            </tr>
            <tr>
              <td>Content-Type</td>
              <td>Same as authorization header.</td>
            </tr>
            <tr>
              <td>X-INVX-REQUEST-UID</td>
              <td>Same as authorization header.</td>
            </tr>
            <tr>
              <td>X-INVX-TIMESTAMP</td>
              <td>Same as authorization header.</td>
            </tr>
            <tr>
              <td>request.body</td>
              <td>As is.</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CreatingRequest;
