const WithdrawWalletAddress = () => {
  return (
    <div id="withdraw-wallet-address" className="hashSection">
      <h1>Get Withdraw Wallet Address</h1>
      <p className="api-path">
        POST /api/v1/digital-asset/withdraw/address/inquiry
      </p>
      <p>
        <strong>Permission</strong>: Read, Withdraw
      </p>
      <p>
        Returns a list of available withdrawal addresses or networks used to
        initiate fiat or cryptocurrency withdrawals.
      </p>
      <div className="divider" />

      <h3>Request Body</h3>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>Field</th>
              <th>Mandatory</th>
              <th>Data type</th>
              <th style={{ minWidth: 400 }}>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>product</td>
              <td>Mandatory</td>
              <td>String</td>
              <td>Shortened product name, e.g., BTC.</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Request Code */}
      <div className="code-display">
        <p> Example Request</p>
        <pre>
          <code>
            {`{
    "product" : "BNB"
}
`}
          </code>
        </pre>
      </div>

      <h3>Response</h3>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>Field</th>
              <th>Data type</th>
              <th style={{ minWidth: 400 }}>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>code</td>
              <td>String</td>
              <td></td>
            </tr>
            <tr>
              <td>message</td>
              <td>String</td>
              <td></td>
            </tr>
            <tr>
              <td>data[].networkId</td>
              <td>Integer</td>
              <td>
                ID of the network that is used to initiate a withdrawal request
              </td>
            </tr>
            <tr>
              <td>data[].network</td>
              <td>String</td>
              <td>Name of the network</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Response Code */}
      <div className="code-display">
        <p> Example Response</p>
        <pre>
          <code>
            {`{
    "code": "0000",
    "message": "SUCCESS",
    "data": [
        {
            "networkId": 1,
            "network": "BTC - Bitcoin"
        }
    ]
}`}
          </code>
        </pre>
      </div>
    </div>
  );
};
export default WithdrawWalletAddress;
