const EstimatedOrderFee = () => {
  return (
    <div id="estimated-order-fee" className="hashSection">
      <h1>Get Estimate Fee</h1>
      <p className="api-path">POST /api/v1/digital-asset/order/fee/inquiry</p>
      <p>
        <strong>Permission</strong>: Read, Trading
      </p>
      <p>Returns an estimate of the transaction fee for a specified order.</p>
      <div className="divider" />

      <h3>Request Body</h3>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>Field</th>
              <th>Mandatory</th>
              <th>Data type</th>
              <th style={{ minWidth: 400 }}>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>symbol</td>
              <td>Mandatory</td>
              <td>String</td>
              <td>Trading symbol, e.g., BTCTHB.</td>
            </tr>
            <tr>
              <td>symbol</td>
              <td>Mandatory</td>
              <td>String</td>
              <td>Trading symbol, e.g., BTCTHB.</td>
            </tr>
            <tr>
              <td>amount</td>
              <td>Mandatory</td>
              <td>Decimal</td>
              <td>Unit amount of the product, e.g., 100 or 116.25.</td>
            </tr>
            <tr>
              <td>price</td>
              <td>Mandatory</td>
              <td>Decimal</td>
              <td>The unit price at which a trade is executed</td>
            </tr>
            <tr>
              <td>side</td>
              <td>Mandatory</td>
              <td>Integer</td>
              <td>
                A numerical representation indicating one of the potential sides
                of a trade
                <br />
                <span>
                  <b>0</b> Buy
                </span>
                <br />
                <span>
                  <b>1</b> Sell
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Request Code */}
      <div className="code-display">
        <p> Example Request</p>
        <pre>
          <code>
            {`{
    "symbol": "BTCTHB",
    "amount": 0.005,
    "price": 77,
    "side": 1
}
`}
          </code>
        </pre>
      </div>

      <h3>Response</h3>

      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>Field</th>
              <th>Data type</th>
              <th style={{ minWidth: 400 }}>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>code</td>
              <td>String</td>
              <td></td>
            </tr>
            <tr>
              <td>message</td>
              <td>String</td>
              <td></td>
            </tr>
            <tr>
              <td>data.orderFee</td>
              <td>String</td>
              <td>
                Trading fee associated with placing an order for a product
              </td>
            </tr>
            <tr>
              <td>data.product</td>
              <td>String</td>
              <td>
                The product indicating the fee charged for a specified order,
                for example, the trading fee is charged in BTC.
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Response Code */}
      <div className="code-display">
        <p> Example Response</p>
        <pre>
          <code>
            {`{
    "code": "0000",
    "message": "SUCCESS",
    "data": {
        "orderFee": "0.02000000",
        "product": "THB"
    }
}
`}
          </code>
        </pre>
      </div>
    </div>
  );
};

export default EstimatedOrderFee;
