import React from "react";

export default function MenuIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5729 7.34375C20.8073 7.34375 21.0416 7.14844 21.0416 6.875V5.625C21.0416 5.39062 20.8073 5.15625 20.5729 5.15625H4.01038C3.73694 5.15625 3.54163 5.39062 3.54163 5.625V6.875C3.54163 7.14844 3.73694 7.34375 4.01038 7.34375H20.5729ZM20.5729 13.5938C20.8073 13.5938 21.0416 13.3984 21.0416 13.125V11.875C21.0416 11.6406 20.8073 11.4062 20.5729 11.4062H4.01038C3.73694 11.4062 3.54163 11.6406 3.54163 11.875V13.125C3.54163 13.3984 3.73694 13.5938 4.01038 13.5938H20.5729ZM20.5729 19.8438C20.8073 19.8438 21.0416 19.6484 21.0416 19.375V18.125C21.0416 17.8906 20.8073 17.6562 20.5729 17.6562H4.01038C3.73694 17.6562 3.54163 17.8906 3.54163 18.125V19.375C3.54163 19.6484 3.73694 19.8438 4.01038 19.8438H20.5729Z"
        fill="black"
      />
    </svg>
  );
}
