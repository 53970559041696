import React from "react";
import { Layout } from "antd";
import SideBarLayout from "./SideBarLayout";
import HeaderLayout from "./HeaderLayout";
import FooterLayout from "./FooterLayout";

export default function MainLayout({ childComponent }) {
  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <SideBarLayout />
      <Layout>
        <HeaderLayout />
        {childComponent}
        <FooterLayout />
      </Layout>
    </Layout>
  );
}
